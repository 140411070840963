<template>
  <popper trigger="clickToOpen" :options="{ placement: 'bottom-start' }">
    <div class="popper">
      <div class="dd-item" @click="recommand">
        <AvatarIcon class="icon" />
        <span>Recommander à un autre utilisateur</span>
      </div>
      <div class="dd-item" @click="copy">
        <CopyIcon class="icon" />
        <span>Copier dans le presse-papier</span>
      </div>
    </div>
    <button class="btn rounded green content-share-btn" slot="reference">
      <ShareIcon />
    </button>
  </popper>
</template>

<script>
import { mapGetters } from 'vuex';
import Popper from "vue-popperjs";
import "vue-popperjs/dist/vue-popper.css";

import ShareIcon from "../../assets/icons/share-icon.svg";
import AvatarIcon from "../../assets/icons/avatar-icon.svg";
import CopyIcon from "../../assets/icons/copy-icon.svg";

export default {
  name: "ShareButton",
  components: {
    ShareIcon,
    CopyIcon,
    AvatarIcon,
    popper: Popper,
  },
  props: {
    todo: { type: Object },
  },
  computed: {
    ...mapGetters([
      'logged',
    ]),
  },
  methods: {
    copy() {
    },
    recommand() {
    },
  },
};
</script>

<style lang="scss" scoped>
.popper {
  box-shadow: var(--shadow-large);
  padding: 5px;
  font-size: 13px;
  color: var(--dark-gray);
  background: #fff;

  .dd-item {
    padding: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon {
      fill: var(--green-login);
      height: 20px;
      width: 20px;
      margin-right: 7px;
    }

    &:hover {
      background: var(--green-bg-share);
    }
  }
}

.content-share-btn {
  box-shadow: none !important;
}
</style>
