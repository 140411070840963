var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nav-btns-ctn"},[_c('div',{class:[
      'inner-ctn',
      'previous',
      { 'grey-disabled': _vm.previousBtnDisabled }
    ],on:{"click":_vm.loadPreviousTodo}},[_c('Popper',{attrs:{"options":{ placement: 'top-center' }}},[_c('div',{staticClass:"popper"},[_vm._v("Précédent")]),_c('PreviousIcon',{attrs:{"slot":"reference"},slot:"reference"})],1),_c('div',{staticClass:"btn-label"},[_vm._v(" Précédent ")])],1),_c('div',{staticClass:"inner-ctn next",class:[
      'inner-ctn',
      'next',
      { 'grey-disabled': _vm.nextBtnDisabled }
    ],on:{"click":_vm.loadNextTodo}},[_c('div',{staticClass:"btn-label"},[_vm._v(" Suivant ")]),_c('Popper',{attrs:{"options":{ placement: 'top-center' }}},[_c('div',{staticClass:"popper"},[_vm._v("Suivant")]),_c('NextIcon',{attrs:{"slot":"reference"},slot:"reference"})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }