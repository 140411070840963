<template>
  <!-- Slider main container -->
  <div ref="slider" class="swiper-container">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper">
      <!-- Slides -->
      <div
        v-for="(image, imageIndex) in images"
        :key="image.id"
        class="swiper-slide"
      >
        <img
          v-if="image.addImage"
          src="../../../public/slider/add_image.png"
          alt=""
          class="small-slider-img"
          @click="showAddImageSection()"
        />
        <img
          v-else-if="image.defaultImage"
          src="../../../public/slider/mountains.png"
          alt=""
        />
        <img
          v-else
          :src="`https://xplo.fr/api/photos/${image.id}/small`"
          alt=""
          class="small-slider-img"
          @click="showFullscreenImage(imageIndex)"
        />
      </div>
    </div>

    <!-- If we need navigation buttons -->
    <div class="swiper-button-prev swiper-button-white"></div>
    <div class="swiper-button-next swiper-button-white"></div>
  </div>
</template>

<script>
import Swiper, { Navigation } from 'swiper';
Swiper.use([Navigation]);
import 'swiper/swiper-bundle.css';

import { mapGetters } from 'vuex';
import { EventBus } from "../../event-bus";
import { showConnectionRequired, showUnderDevelopment } from "../../utils";

export default {
  name: "SmallSlider",
  props: {
    images: { type: Array, default: () => [] },
    position: { type: String, default: '' },
  },
  data() {
    return {
      slider: null,
      sliderSettings: {
        observer: true, 
        observeParents: true,
        slidesPerView: 2,
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          // when window width is >= 320px
          420: {
            slidesPerView: 3,
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      'logged',
    ]),
    isUnderTheMap() {
      return this.position === "map";
    },
    isUnderTheDescription() {
      return this.position === "description";
    },
  },
  mounted () {
    this.slider = new Swiper(this.$refs.slider, this.sliderSettings)
  },
  methods: {
    showFullscreenImage(imageId) {
      EventBus.$emit("showFullscreenImage",imageId);
    },
    showAddImageSection() {
      if (this.logged) {
        showUnderDevelopment();
      } else {
        showConnectionRequired();
      }
    }
  }
};
</script>

<style lang="scss">
</style>
