import vm from "./main";
import store from "./store";
import { putReference } from "./service/api.js";

function showConnectionRequired () {
  vm.$snotify.error(
    "Vous devez d'abord vous connecter",
    {
      timeout: 2000,
      showProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
    }
  );
}

function showUnderDevelopment () {
  vm.$snotify.success("Fonctionnalité en cours de développement", {
    timeout: 2000,
    showProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
  });
}

function highlightExactMatch(prediction, searchQuery) {
  // Check if search query is included in prediction
  const queryIncluded = prediction
    .toUpperCase()
    .includes(searchQuery.toUpperCase());
  if (!queryIncluded) {
    // If it is not, return the prediction in a span tag
    return `${prediction}`;
  } else {
    // If it is, return the similar part in a strong tag, the rest in span tags
    const index = prediction.toUpperCase().indexOf(searchQuery.toUpperCase());
    const start = prediction.slice(0, index);
    const similarPart = prediction.slice(index, index + searchQuery.length);
    const end = prediction.slice(index + searchQuery.length, prediction.length);
    return `${start}<strong>${similarPart}</strong>${end}`;
  }
}

function formatDate(date) {
  let dd = '' + date.getDate();
  let mm = '' + (date.getMonth() + 1);
  let yyyy = date.getFullYear();

  if (mm.length < 2) 
      mm = '0' + mm;
  if (dd.length < 2) 
      dd = '0' + dd;

  return [dd, mm, yyyy].join('/');
}

async function saveUserLocation(position) {
  store.dispatch("setUserAddress", null);
  store.dispatch("setUserLocation", [
    position.coords.latitude,
    position.coords.longitude,
  ]);
  if (store.getters.userNameDefined)
  {
    putReference(null, position.coords.latitude, position.coords.longitude);
  }
}

function handleLocationError(error) {
  // Set default user position in Paris
  store.dispatch("setUserAddress", 'Paris');
  store.dispatch("setUserLocation", [48.86358549323598, 2.3421478271484375]);
  // Notify user
  vm.$snotify.error(
    "Il semble impossible d'obtenir votre position, voici un exemple près de Paris",
    {
      timeout: 2000,
      showProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
    }
  );
  // Console error
  console.log(`ERROR (${error.code}): ${error.message}`);
}

function getGeolocation() {
  // If geolocation is supported by user browser
  if (navigator.geolocation) {
    // Get current position, handle success (saveUserLocation) and failing cases (handleLocationError)
    navigator.geolocation.getCurrentPosition(
      saveUserLocation,
      handleLocationError
    );
  }
  // Else (geolocation unsupported by browser)
  else {
    console.log("Geolocation is not supported by this browser.");
  }
}

export { showConnectionRequired, showUnderDevelopment, highlightExactMatch, formatDate, getGeolocation };
