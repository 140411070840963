<template>
  <ul class="recommandations-list">
    <li
      v-for="item in contributions"
      :key="item.id"
      class="recommandation-item"
      :class="{ 'is-new': item.isNew }"
    >
      <h2 class="title">
        <div v-if="!item.seen" class="green-dot"></div>
        <span>{{ item.text }}</span>
      </h2>
      <span class="info-ctn">{{ new Date(item.date).toLocaleString() }}</span>
    </li>
  </ul>
</template>

<script>
import { getContributions } from "../../service/api.js";

export default {
  name: "Contributions",
  async created() {
    const contributions = await getContributions();
    contributions.data.forEach((contribution) => {
      this.contributions.push(contribution);
    });
  },
  data() {
    return {
      contributions: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.recommandations-list {
  padding: 0 10%;
  margin: 0;
  list-style-type: none;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;

  .recommandation-item {
    display: flex;
    align-items: center;
    padding: 25px;

      .title {
        margin: 0;
        color: var(--dark-green);
        display: flex;
        align-items: center;

        .green-dot {
          background: var(--dark-green);
          min-width: 15px;
          width: 15px;
          min-height: 15px;
          height: 15px;
          border-radius: 50%;
          margin-right: 20px;
        }
      }

      .info-ctn {
        display: flex;
        align-items: center;

        span:first-child {
          font-weight: 600;
          margin-right: 5px;
        }
      }
    }

  .recommandation-item:not(:last-of-type) {
    border-bottom: 1px solid var(--border-gray);
  }
}
</style>
