const axios = require("axios");
import store from "../store";

// todos

function getAnswers() {
  return axios.get("/api/todos/answers");
}

function getAnswersNumber() {
  return axios.get("/api/todos/answers/number");
}

function getNextTodo(currentId, distance) {
  console.log(store.getters.userLocation)
  const [userLat, userLng] = store.getters.userLocation;
  let currentIdOption = "";
  if (currentId) currentIdOption = `&currentId=${currentId}`;
  const distanceOption = `&distance=${distance}`;
  return axios.get(
    `/api/todos/next?userLatitude=${userLat}&userLongitude=${userLng}${currentIdOption}${distanceOption}`
  );
}

function getPreviousTodo(currentId) {
  return axios.get(`/api/todos/previous?currentId=${currentId}`);
}

function getArroundTodos() {
  const [userLat, userLng] = store.getters.userLocation;
  return axios.get(
    `/api/todos/arround?userLatitude=${userLat}&userLongitude=${userLng}`
  );
}

function getTodoById(id) {
  return axios.get(`/api/todos/${id}`);
}

function rateTodo(payload) {
  return axios.put(`/api/todos/${payload.todoId}/rate`, {
    rating: payload.rating,
    userLatitude: payload.pos[0],
    userLongitude: payload.pos[1],
  });
}

function setTodoAsDone(payload) {
  console.log('done3 '+payload.todoId);
  return axios.put(`/api/todos/${payload.todoId}/done`, {
    done: payload.done,
    userLatitude: payload.pos[0],
    userLongitude: payload.pos[1],
  });
}

function addPoi(payload) {
  return axios.post("/api/todos/poi", payload);
}

function addEvent(payload) {
  return axios.post("/api/todos/event", payload);
}

// photos

function getPhotoSmall(id) {
  return axios.get(`/api/photos/${id}/small`);
}

function ratePhoto(photoId, rating) {
  return axios.put(`/api/photos/${photoId}/rate`, {
    rating: rating,
  });
}

// did you knows

function addDidYouKnow(todo, text) {
  return axios.post("/api/didyouknows", { 
    todo, 
    text 
  });
}

function rateDidYouKnow(todoId, rating) {
  return axios.put(`/api/didyouknows/${todoId}/rate`, {
    rating
  });
}

function updateDidYouKnow(didYouKnowId, text) {
  return axios.put(`/api/didyouknows/${didYouKnowId}`, {
    text
  });
}

// search

function getSearchTodos(keyword) {
  return axios.get(`/api/search/todo/${keyword}`);
}

function getSearchAddresses(keyword) {
  return axios.get(`/api/search/address/${keyword}`);
}

function getAddressDetails(id) {
  return axios.get(`/api/search/address/details/${id}`);
}

function getSearchUsers(keyword) {
  return axios.get(`/api/search/user/${keyword}`);
}

// perso

function sendMessage(payload) {
  return axios.post("/api/perso/messages/", {
    receiver: payload.receiver,
    todo: payload.todo,
    text: payload.text,
  });
}

function getMessagesReceived() {
  return axios.get("/api/perso/messages/received");
}

function getMessagesSent() {
  return axios.get("/api/perso/messages/sent");
}

function setMessageAsSeen(messageId) {
  return axios.put(`api/perso/messages/received/${messageId}/seen`, {
    seen: true,
  });
}

function getNotifications() {
  return axios.get("/api/perso/notifications");
}

function getContributions() {
  return axios.get("/api/perso/systemmessages");
}

// auth

function login(email, password) {
  return axios.post("/api/auth/login", {
    email,
    password
  });
}

function logout() {
  return axios.get("/api/auth/logout");
}

function signup(name, email, password, passwordVerif) {
  return axios.post("/api/auth/register", {
    name,
    email,
    password,
    passwordVerif,
  });
}

function getAuthentificationStatus() {
  return axios.get("/api/auth/status");
}

// reference

function putReference(address, latitude, longitude) {
  return axios.put(`api/map/reference`, {
    address: address,
    latitude: latitude,
    longitude: longitude,
  });
}

function getCurrentReference() {
  return axios.get("/api/map/reference");
}

function getReferenceHistory() {
  return axios.get("/api/map/reference/addresses");
}

function getLast() {
}

export {
  addDidYouKnow,
  addEvent,
  addPoi,
  getAddressDetails,
  getAnswers,
  getAnswersNumber,
  getArroundTodos,
  getAuthentificationStatus,
  getContributions,
  getCurrentReference,
  getLast,
  getMessagesReceived,
  getMessagesSent,
  getNotifications,
  getPhotoSmall,
  getPreviousTodo,
  getReferenceHistory,
  getSearchAddresses,
  getSearchTodos,
  getSearchUsers,
  getNextTodo,
  getTodoById,
  login,
  logout,
  putReference,
  rateTodo,
  setTodoAsDone,
  rateDidYouKnow,
  ratePhoto,
  sendMessage,
  setMessageAsSeen,
  signup,
  updateDidYouKnow,
};
