<template>
  <header>
    <b-navbar fixed="top" toggleable="lg">
      <b-navbar-brand to="/">
        <img src="logo.svg" class="header-logo">
      </b-navbar-brand>

      <div class="d-flex order-2 order-lg-3">
        <b-navbar-nav>
          <HeaderProfileDropdown v-if="userNameDefined" />
          <b-nav-item v-else-if="logged" class="header-login">Connexion</b-nav-item>
          <b-nav-item v-else class="header-login" to="login">Se connecter</b-nav-item>
        </b-navbar-nav>
        <b-navbar-toggle id="nav-toggle" target="nav-collapse">
          <template>
            <HamburgerIcon />
          </template>
        </b-navbar-toggle>
      </div>

      <b-collapse id="nav-collapse" class="order-3 order-lg-2" v-model="dropdownShown" is-nav>
        <b-navbar-nav class="ml-auto">
          <HeaderSearchInput />
          <HeaderSuggestions />
          <b-nav-item class="green-white header-item uppercase bold" @click="goTo('/history')"><HistoryIcon /> Mes réponses</b-nav-item>
          <b-nav-item class="white-green header-item uppercase bold" @click="goTo('/directory')"><RepertoryIcon /> Répertoire</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </header>
</template>

<script>
import { EventBus } from "../../event-bus";
import { mapGetters } from 'vuex';
import { showConnectionRequired } from "../../utils";
import { getAnswersNumber } from "../../service/api.js";

import HeaderSearchInput from "../Header/HeaderSearchInput";
import HeaderSuggestions from "../Header/HeaderSuggestions";
import HeaderProfileDropdown from "../Header/HeaderProfileDropdown";

import HamburgerIcon from "../../assets/icons/hamburger-icon.svg";
import RepertoryIcon from "../../assets/icons/search-icon.svg";
import HistoryIcon from "../../assets/icons/reverse-clock-icon.svg";

export default {
  name: "Header",
  components: {
    HeaderSearchInput,
    HeaderSuggestions,
    HeaderProfileDropdown,
    HamburgerIcon,
    RepertoryIcon,
    HistoryIcon,
  },
  data() {
    return {
      dropdownShown: false,
      answersNumber: 0,
    };
  },
  computed: {
    ...mapGetters([
      'logged',
      'userName',
      'userNameDefined',
    ]),
  },
  watch: {
    logged() {
      this.synchronizeAnswersNumber();
    }
  },
  created() {
    window.addEventListener("click", this.checkClick);
    this.synchronizeAnswersNumber();
    EventBus.$on("synchronizeAnswersNumber", this.synchronizeAnswersNumber);
  },
  beforeDestroy() {
    EventBus.$off("synchronizeAnswersNumber");
  },
  destroyed() {
    window.removeEventListener("click", this.checkCLick);
  },
  methods: {
    checkClick(event) {
      const clickNotClosing = event.composedPath().some(
        (el) => (el.id === "nav-toggle" || el.id === "header-search-input")
      );
      if (!clickNotClosing) {
        this.dropdownShown = false;
      }
    },
    goTo(page) {
      if (this.logged) {
        if (page === '/history') {
          console.log('number')
          console.log(this.answersNumber)
          if (this.answersNumber) {
            this.$router.push(page);
          } else {
            this.$snotify.error("Vous n'avez pas encore évalué de proposition", {
              timeout: 2000,
              showProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true,
            });
          }
        } else {
          this.$router.push(page);
        }
      } else {
        showConnectionRequired();
      }
    },
    async synchronizeAnswersNumber() {
      if (this.logged) {
        let answersNumber = await getAnswersNumber();
        this.answersNumber = answersNumber.data.number;
        console.log('synchro');
        console.log(this.answersNumber)
      }
    },
  },
};
</script>

<style lang="scss">
</style>
