<template>
  <div class="user-choice-ctn">
    <popper trigger="click" class="icon" :options="{ placement: 'top-start' }">
      <div class="popper">
        Affiche les
        <HeartIcon class="heart-icon" />
        pas encore marqués comme "Déjà faits", et dont la date éventuelle n'est
        pas encore passée.
      </div>
      <InfoIcon slot="reference" />
    </popper>
    <span>Filtrer</span>
    <input type="checkbox" v-model="filteredChecked" @change="updateFiltered" />
  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import Popper from "vue-popperjs";
import "vue-popperjs/dist/vue-popper.css";
import InfoIcon from "../../assets/icons/info-icon.svg";
import HeartIcon from "../../assets/icons/heart-icon.svg";

export default {
  name: "FilterPoints",
  components: {
    InfoIcon,
    HeartIcon,
    popper: Popper,
  },
  data() {
    return {
      filteredChecked: false,
    };
  },
  methods: {
    updateFiltered() {
      EventBus.$emit("updateFiltered", this.filteredChecked);
    },
  },
};
</script>

<style lang="scss">
.user-choice-ctn {
  position: absolute;
  bottom: 30px;
  left: 20px;
  z-index: 401;
  display: flex;
  align-items: center;
  padding: 7px;
  border-radius: var(--sm-radius);
  background: white;
  cursor: pointer;
  box-shadow: var(--shadow-large);

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 15px;
      height: 15px;
    }
  }

  .heart-icon {
    height: 10px;
    width: 10px;
    margin: 0 2px;

    path {
      fill: var(--green);
    }
  }

  span {
    margin-right: 10px;
    font-size: 0.85rem;
  }

  .popper {
    width: 200px;
    margin-bottom: 15px;
    padding: 12px 5px;
  }
}
</style>
