<template>
  <section class="col-lg-6 content-section" :name="name">
    <DescriptionLoader
      v-if="isLoading"
      class="description-loader"
    />

    <div v-else class="content full-width">
      <div class="content-ctn">
        <div class="content-header-wrapper">
          <div class="content-header">
            <h1 class="uppercase bold">{{ name }}</h1>
          </div>
          <ShareButton :todo="shareBtnFormattedTodo" />
        </div>

        <div v-if="!isEvent" class="content-subtitle medium">
          <div class="content-stars">
            <star-rating
              :read-only="true"
              :rating="stars"
              :increment="0.1"
              :active-color="'#be7129'"
              :inactive-color="'#ffffff'"
              :border-color="'#be7129'"
              :border-width="2"
              :star-size="16"
              :rounded-corners="true"
              :show-rating="false"
            />
          </div>
          <div class="content-reviews uppercase">{{ reviews }} avis</div>
        </div>
        <div v-else class="date-and-place-ctn">
          <div>
            <CalIcon />
            <span>{{ formattedDate }}</span>
          </div>
          <div>
            <PinIcon />
            <span>{{ address }}</span>
          </div>
        </div>

        <p
          class="content-description"
          v-html="description"
          ref="contentDescription"
        ></p>

        <DidYouKnows
          v-if="!isEvent"
          :didYouKnows="didYouKnows"
          :poiId="poiId"
        />

        <a v-else :href="website" class="more-info">Plus d'informations</a>

        <div class="swiper-description-container">
          <SmallSlider
            :images="smallSliderImages"
            position="description"
          />
        </div>
      </div>

      <ActionButtons
        :liked="liked"
        :disliked="disliked"
        :done="done"
        :todoId="todoId"
      />

      <PreviousNextButtons
        v-if="!isDirectory"
        :previousBtnDisabled="previousBtnDisabled"
        :nextBtnDisabled="nextBtnDisabled"
      />
    </div>
  </section>
</template>

<script>
import StarRating from "vue-star-rating";
import gsap from "gsap";
import { mapGetters } from 'vuex';
import { formatDate } from "../../utils";

import DescriptionLoader from "./DescriptionLoader";
import DidYouKnows from "./DidYouKnows";
import ShareButton from "./ShareButton";
import ActionButtons from "./ActionButtons";
import PreviousNextButtons from "./PreviousNextButtons";
import CalIcon from "../../assets/icons/calendar-icon.svg";
import PinIcon from "../../assets/icons/pin-icon.svg";
import SmallSlider from "../Slider/SmallSlider";

export default {
  name: "Description",
  components: {
    ActionButtons,
    CalIcon,
    DescriptionLoader,
    DidYouKnows,
    ShareButton,
    StarRating,
    PinIcon,
    PreviousNextButtons,
    SmallSlider,
  },
  props: {
    todoId: { type: Number, default: 0 },
    poiId: { type: Number, default: 0 },
    isEvent: { type: Boolean, default: false },
    name: { type: String, default: "" },
    description: { type: String, default: "" },
    liked: { type: Boolean, default: false },
    disliked: { type: Boolean, default: false },
    done: { type: Boolean, default: false },
    images: { type: Array, default: () => [] },
    smallSliderImages: { type: Array, default: () => [{ defaultImage: true }, { defaultImage: true }, { defaultImage: true }] },
    stars: { type: Number, default: 0 },
    reviews: { type: Number, default: 0 },
    didYouKnows: { type: Array, default: () => [] },
    startDate: { type: Date, default: () => new Date() },
    endDate: { type: Date, default: () => new Date() },
    address: { type: String, default: "" },
    website: { type: String, default: "" },
    previousBtnDisabled: { type: Boolean, default: true },
    nextBtnDisabled: { type: Boolean, default: true },
  },
  data() {
    return {
      didYouKnowsHidden: true,
    };
  },
  computed: {
    ...mapGetters([
      'userLocationDefined',
    ]),
    isLoading() {
      return !this.userLocationDefined || !this.todoId
    },
    isDirectory () {
      return this.$route.name === 'directory'
    },
    formattedStartDate () {
      return formatDate(this.startDate)
    },
    formattedEndDate () {
      return formatDate(this.endDate)
    },
    formattedDate () {
      if (this.formattedStartDate === this.formattedEndDate)
        return 'Le '+this.formattedStartDate;
      else
        return 'Du '+this.formattedStartDate+' au '+this.formattedEndDate;
    },
    shareBtnFormattedTodo() {
      return {
        id: this.todoId,
        name: this.name,
      };
    },
  },
  watch: {
    todoId() {
      if (document.getElementsByClassName("content-ctn").length) {
        const tl = gsap.timeline();
        tl.to(".content-ctn", { duration: 0.01, opacity: 0 });
        tl.to(".content-ctn", { duration: 0.3, opacity: 1 }, "+=.3");
      }
    },
  },
  methods: {
    formatDate: formatDate
  },
};
</script>

<style lang="scss">
.date-and-place-ctn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > div {
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
      height: 15px;
      width: 15px;

      path {
        fill: var(--orange);
      }
    }
  }

  > div:first-of-type {
    margin-right: 25px;
  }
}

@media screen and (max-width: 640px) {
  .date-and-place-ctn {
    margin-top: 15px;
    flex-direction: column;
    align-items: flex-start;
  }
}

.more-info {
  display: inline-block;
  margin-top: 20px;
  color: var(--green-login);
  text-decoration: underline;
}

.content-img-ctn {
  position: relative;

  .icon-ctn {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: flex-start;
    padding: 0 2px;
    border: 1px dashed var(--light-gray);
    border-top-right-radius: var(--sm-radius);
    background: rgba(255, 255, 255, 0.2);

    svg {
      width: 10px;
      height: 10px;

      path {
        fill: var(--medium-gray);
      }
    }
  }
}

@media screen and (min-width: 600px) {
  .only-small-screen {
    display: none;
  }
}
</style>
