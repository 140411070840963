<template>
  <section class="proposition-form col-lg-6 content-section">
    <div class="content content-inner full-width">
      <h2>Ajouter un {{ isEvent ? "événement" : "point d'intérêt" }}</h2>
      <div>
        <div class="input-ctn">
          <label for="name">Nom</label>
          <input type="text" id="name" v-model="name" />
        </div>
        <div class="input-ctn">
          <label for="description">Description</label>
          <textarea
            id="description"
            v-model="description"
          ></textarea>
        </div>
        <!-- <div v-if="isEvent" class="input-ctn">
          <label for="place">Lieu</label>
          <input type="text" id="place" v-model="place" />
        </div> -->
        <div v-if="isEvent" class="input-ctn date-picker-ctn">
          <div class="date-picker">
            <span class="date-picker-label">Date de début</span>
            <el-date-picker
              v-model="dateFrom"
              type="date"
              format="dd-MM-yyyy"
              placeholder="Sélectionner"
              @change="maybeUpdateDateTo"
            >
            </el-date-picker>
          </div>
          <div class="date-picker">
            <span class="date-picker-label">Date de fin</span>
            <el-date-picker
              v-model="dateTo"
              type="date"
              format="dd-MM-yyyy"
              placeholder="Sélectionner"
            >
            </el-date-picker>
          </div>
        </div>
        <!-- <Button
          :classes="
            'btn green outline bold uppercase content-add-didyouknows-btn add-pic-btn'
          "
          :text="'Ajouter des photos'"
        >
          <template v-slot:icon>
            <PlusIcon />
          </template>
        </Button> -->
      </div>
      <button class="send-btn" @click="addProposition">Envoyer</button>
    </div>
  </section>
</template>

<script>
import { addPoi, addEvent } from "../../service/api.js";

import { DatePicker } from "element-ui";

export default {
  name: "AddTodoForm",
  components: {
    "el-date-picker": DatePicker,
  },
  props: {
    newTodoType: { type: String, default: '' },
    newTodoLocation: { type: Array, default: () => [] },
  },
  data() {
    return {
      didYouKnows: [],
      dateFrom: undefined,
      dateTo: undefined,
      name: undefined,
      description: undefined,
      place: undefined,
    };
  },
  computed: {
    isEvent() {
      return this.newTodoType === "event";
    },
  },
  methods: {
    async addProposition() {
      if (!this.isEvent) {
        const payload = {
          name: this.name,
          description: this.description,
          latitude: this.newTodoLocation[0],
          longitude: this.newTodoLocation[1],
          address: null,
          didyouknows: this.didYouKnows.map((el) => el.text),
        };
        const response = await addPoi(payload);
        if (response.data === "OK") this.handlePropSendSuccessfully();
      } else {
        const payload = {
          name: this.name,
          description: this.description,
          latitude: this.newTodoLocation[0],
          longitude: this.newTodoLocation[1],
          address: null,
          addressId: null
        };
        if (this.dateFrom && this.dateTo) {
          payload.startDate = this.dateFrom;
          payload.endDate = this.dateTo;
        }
        const response = await addEvent(payload);
        if (response.data === "OK") this.handlePropSendSuccessfully();
      }
    },
    handlePropSendSuccessfully() {
      this.$snotify.success("Proposition envoyée avec succès", {
        timeout: 2000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
      });
      setTimeout(() => {
        this.$router.push("/");
      }, 2000);
    },
    maybeUpdateDateTo() {
      if (!this.dateTo) this.dateTo = this.dateFrom;
    },
  },
};
</script>

<style lang="scss">
.proposition-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  h2 {
    color: var(--orange);
    font-size: 1.7em;
    letter-spacing: 1px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .input-ctn {
    display: flex;
    flex-direction: column;

    label,
    .date-picker-label {
      font-weight: 600;
      color: var(--medium-gray);
      text-transform: uppercase;
      font-size: 0.85rem;
      margin-bottom: 2px;
    }

    > input,
    > textarea {
      background: #fff;
      border: 1px solid var(--border-gray);
      border-radius: var(--sm-radius);
      padding: 5px 0 5px 10px;
    }

    > textarea {
      max-height: 300px;
      padding: 5px 10px;
    }
  }

  .input-ctn:not(:last-of-type) {
    margin-bottom: 20px;
  }

  .didyouknow-list {
    margin-top: 20px;

    li {
      color: var(--gray);
      width: 100%;

      span {
        width: 100%;
        border: 1px solid var(--light-gray);
        padding: 10px;
        font-size: 0.8rem;
        border-radius: var(--sm-radius);
      }

      input {
        background: #fff;
        border: 1px solid var(--border-gray);
        border-radius: var(--sm-radius);
        width: 100%;
        padding: 5px 0 5px 10px;
      }
    }

    li:not(:last-of-type) {
      margin-bottom: 5px;
    }
  }

  .date-picker-ctn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .date-picker {
      display: flex;
      flex-direction: column;
      width: 45%;
    }
  }

  .add-pic-btn {
    align-self: flex-start;
    margin-top: 20px;
  }

  .send-btn {
    margin-top: 40px;
    align-self: center;
    padding: 10px 70px;
    border-radius: var(--lg-radius);
    background: var(--green-login);
    color: #fff;
    font-weight: 600;
    border: 0;
  }
}

.el-date-editor {
  width: 100% !important;
}
</style>
