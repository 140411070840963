<template>
  <div class="fullscreen-slider-wrapper">
    <SliderActions
      :image="images[imageNumber]"
      :imageNumber="imageNumber"
    />
    <b-carousel
      v-model="imageNumber"
      :interval="0"
      no-wrap
      controls
    >
      <b-carousel-slide
        v-for="image in images"
        :key="image.src"
      >
        <template #img>
          <img
            class="fullscreen-slider-img"
            :src="`https://xplo.fr/api/photos/${image.id}`"
            alt="image slot"
          />
        </template>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
import SliderActions from "./SliderActions";

export default {
  name: "FullscreenCarousel",
  components: {
    SliderActions,
  },
  props: {
    initialImageNumber: { type: Number, default: 0 },
    images: { type: Array, default: () => [] },
  },
  data() {
    return {
      imageNumber: this.initialImageNumber,
    };
  },
};
</script>

<style lang="scss">
.fullscreen-slider-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(13, 13, 13, 0.75);
  z-index: 2000;
}
.carousel {
  height:100%;
}

.carousel-inner {
  height:100%;
}

.carousel-item {
  height: 100%;
}

.fullscreen-slider-img {
  position: absolute;
  margin:auto;
  left:0;
  top:0;
  right:0;
  bottom: 0;
}

@media screen and (max-width: 640px) {
  .fullscreen-slider-img {
    max-width: 90%;
    max-height: 90%;
  }
}

@media screen and (min-width: 640px) {
  .fullscreen-slider-img {
    max-width: 80%;
    max-height: 80%;
  }
}
</style>
