import Vue from "vue";
import Vuex from "vuex";
import { getAuthentificationStatus } from "./service/api.js";
import { getGeolocation } from "./utils";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    userLocation: [null, null],
    userAddress: null,
    userName: null,
    logged: false,
    distance: 1,
  },
  mutations: {
    setUserLocation(state, payload) {
      state.userLocation = payload;
    },
    setUserAddress(state, payload) {
      state.userAddress = payload;
    },
    setUserName(state, payload) {
      state.userName = payload;
    },
    setUserAsLogged(state, payload) {
      state.logged = payload;
    },
    setDistance(state, payload) {
      state.distance = payload;
    },
  },
  actions: {
    setUserLocation(context, payload) {
      context.commit("setUserLocation", payload);
    },
    setUserAddress(context, payload) {
      context.commit("setUserAddress", payload);
    },
    async synchronizeUser(context) {
      const status = await getAuthentificationStatus();
      context.commit("setUserName", status.data.name);
      if (status.data.name !== null) {
        context.commit("setUserAsLogged", true);
      }
      if (status.data.address === null) {
        getGeolocation();
      } else {
        context.commit("setUserAddress", status.data.address);
        context.commit("setUserLocation", [status.data.latitude, status.data.longitude]);
      }
      context.commit("setDistance", status.data.distance);
    },
    login(context) {
      context.commit("setUserAsLogged", true);
      context.commit("setUserLocation", [null, null]);
    },
    logout(context) {
      context.commit("setUserAsLogged", false);
      context.commit("setUserName", null);
      context.commit("setUserLocation", [null, null]);
      context.commit("setUserAddress", null);
      context.commit("setDistance", 1);
    },
    setDistance(context, payload) {
      context.commit("setDistance", payload);
    },
  },
  getters: {
    distance: (state) => {
      return state.distance;
    },
    userLocationDefined: (state) => {
      return state.userLocation[0] !== null && state.userLocation[1] !== null;
    },
    logged: (state) => {
      return state.logged;
    },
    userName: (state) => {
      return state.userName;
    },
    userNameDefined: (state) => {
      return state.userName !== null;
    },
    userLocation: (state) => {
      return state.userLocation;
    },
    userAddress: (state) => {
      return state.userAddress;
    },
  },
});

export default store;
