<template>
  <ul class="recommandations-list">
    <li
      v-for="item in recommandations"
      :key="item.title"
      class="recommandation-item"
      :class="{ 'is-new': item.isNew }"
    >
      <div class="top-part" @click="showRecommandation(item)">
        <h2 class="title">
          <div v-if="item.isNew" class="green-dot"></div>
          <span>{{ item.title }}</span>
        </h2>
        <div class="info-ctn">
          <span>Recommandé par {{ item.provider }},</span>
          <span>{{ item.date }}</span>
          <ArrowIcon
            class="arrow-icon"
            :class="{ 'towards-bottom': !item.hidden }"
          />
        </div>
      </div>
      <div class="bottom-part" :class="{ 'is-hidden': item.hidden }">
        <p class="message">{{ item.message }}</p>
        <button>Voir la fiche</button>
      </div>
    </li>
  </ul>
</template>

<script>
import { getMessagesReceived, setMessageAsSeen } from "../../service/api.js";
import ArrowIcon from "../../assets/icons/arrow-icon.svg";

export default {
  name: "RecommandationsReceived",
  components: {
    ArrowIcon,
  },
  async created() {
    const messages = await getMessagesReceived();
    this.recommandations = [];
    messages.data.forEach((item) =>
      this.recommandations.push({
        id: item.id,
        todo: item.todo,
        title: item.todo_name,
        provider: item.sender,
        date: new Date(item.date).toLocaleString(),
        message: item.text,
        hidden: true,
        isNew: item.seen === 0,
      })
    );
  },
  data() {
    return {
      recommandations: [],
    };
  },
  methods: {
    async showRecommandation(item) {
      if (item.hidden) {
        this.recommandations.forEach((el) => {
          el.hidden = true;
        });
        item.hidden = false;
      } else item.hidden = true;
      if (item.isNew) {
        await setMessageAsSeen(item.id);
        item.isNew = false;
      }
    },
  },
};
</script>

<style lang="scss">
</style>
