<template>
  <div class="form-ctn signup-form">
    <img class="logo" src="../../assets/logo.png" alt="xplo-logo" />
    <h2>Inscription</h2>
    <form onsubmit="return false">
      <label for="last-name">Nom</label>
      <input type="text" id="last-name" v-model="name" />
      <label for="email">Adresse mail</label>
      <input type="email" id="email" v-model="email" />
      <label for="password">Mot de passe</label>
      <input type="password" id="password" v-model="password" />
      <label for="password-confirm">Confirmation mot de passe</label>
      <input type="password" id="password-confirm" v-model="passwordVerif" />
      <span v-if="errorMessageVisible" class="error-message">Informations incorrectes</span>
      <button @click="signup">Je m'inscris</button>
    </form>
    <hr />
    <span class="rgpd-message">
      En créant ce compte vous acceptez que vos données soient traitées, dans le cadre d'un projet de recherche exploratoire, par l’IRT SystemX : 
      1) Afin de vous permettre l'accès à un espace personnel   
      2) Afin de vous proposer des recommandations personnalisées.<br>
      Vous pouvez retirer votre consentement ou faire valoir vos droits sur vos données à tout moment, en écrivant à RGPD@irt-systemx.fr .
      Vous pouvez également introduire une réclamation concernant le traitement de vos données personnelles auprès de la CNIL.
    </span>
    <hr />
    <div class="no-account-ctn">
      <div class="question">Déja un compte ?</div>
      <span class="sign-up-link" @click="emitToggleForm">Connectez-vous ici</span>
    </div>
  </div>
</template>

<script>
import { signup } from "../../service/api.js";

export default {
  name: "SignupForm",
  data() {
    return {
      email: undefined,
      errorMessageVisible: false,
      name: undefined,
      password: undefined,
      passwordVerif: undefined,
    };
  },
  methods: {
    signup() {
      signup(this.name, this.email, this.password, this.passwordVerif)
        .then(() => {
          this.$store.dispatch('login');
          this.$router.push("/");
          this.$store.dispatch('synchronizeUser');
        })
        .catch((err) => {
          this.errorMessageVisible = true;
          console.error(err);
        });
    },
    emitToggleForm() {
      this.$emit("toggleForm");
    },
  },
};
</script>

<style lang="scss" scoped>
  .rgpd-message {
    display:block;
    text-align: justify;
    font-size: 0.7rem;
    color: var(--grey-text-login);
  }
</style>
