<template>
  <div>
    <Header />
    <main class="container-outer">
      <div class="profile-ctn">
        <Menu />
        <div class="profile-content">
          <RecommandationsReceived v-if="isSuggestionsReceived" />
          <RecommandationsSent v-else-if="isSuggestionsSent" />
          <Contributions v-else-if="isContributions" />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { EventBus } from "../event-bus";
import Header from "../components/Header/Header";
import Menu from "../components/Profile/Menu";
import RecommandationsReceived from "../components/Profile/RecommandationsReceived";
import RecommandationsSent from "../components/Profile/RecommandationsSent";
import Contributions from "../components/Profile/Contributions";

export default {
  name: "Profile",
  components: {
    Contributions,
    Header,
    Menu,
    RecommandationsReceived,
    RecommandationsSent,
  },
  computed: {
    isContributions() {
      return this.currentProfileSection === "contributions";
    },
    isSuggestionsReceived() {
      return this.currentProfileSection === "suggReceived";
    },
    isSuggestionsSent() {
      return this.currentProfileSection === "suggSent";
    },
  },
  created() {
    EventBus.$on("profileMenuItemClicked", this.handleMenuItemClicked);
  },
  beforeDestroy() {
    EventBus.$off("profileMenuItemClicked");
  },
  data() {
    return {
      currentProfileSection: "contributions",
    };
  },
  methods: {
    handleMenuItemClicked(payload) {
      this.currentProfileSection = payload;
    },
  },
};
</script>

<style lang="scss">
.profile-ctn {
  display: flex;
  height: calc(100vh - 66px);

  .profile-content {
    width: 70%;
  }
}
</style>
