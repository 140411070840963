import Home from "../pages/Home";
import Login from "../pages/Login";
import Profile from "../pages/Profile";

const routes = [
  {
    name: "home",
    path: "/",
    component: Home,
  },
  {
    name: "history",
    path: "/history",
    component: Home,
  },
  {
    name: "directory",
    path: "/directory",
    component: Home,
  },
  {
    name: "login",
    path: "/login",
    component: Login,
  },
  {
    name: "profile",
    path: "/profile",
    component: Profile,
  },
];

export default routes;
