<template>
  <div>
    <b-nav-item class="header-login" id="header-login" @click="toggleDropdown">
      <AlertNotification v-show="newNotification" class="header-profile-alert" /> 
      {{ userName }} 
      <b-icon-chevron-down v-if="!dropdownShown"></b-icon-chevron-down>
      <b-icon-chevron-up v-else></b-icon-chevron-up>
    </b-nav-item>
    <div v-show="dropdownShown" class="header-profile-dropdown">
      <div class="header-profile-notif-list">
        <div
          v-for="item in userNotifications"
          :key="item.id"
          class="header-profile-notif-item"
        >
          <div v-if="!item.seen" class="header-profile-notif-dot"></div>
          <div class="header-profile-notif-content">
            <div :class="{ 'bold': !item.seen }">
              {{ item.text }}
            </div>
            <div>
              {{ new Date(item.date).toLocaleString() }}
            </div>
          </div>
        </div>
      </div>
      <router-link to="profile" tag="div" class="header-profile-item">
        Espace personnel
      </router-link>
      <div class="header-profile-item" @click="logout">
        Me déconnecter
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { EventBus } from "../../event-bus";
import { getNotifications, logout } from "../../service/api.js";
import { getGeolocation } from "../../utils";
import AlertNotification from "../../assets/icons/alert-notification.svg";
import { BIconChevronDown, BIconChevronUp } from 'bootstrap-vue'

export default {
  name: "HeaderProfileDropdown",
  components: {
    AlertNotification,
    BIconChevronDown,
    BIconChevronUp,
  },
  data() {
    return {
      dropdownShown: false,
      userNotifications: [],
    };
  },
  computed: {
    ...mapGetters([
      'userName',
    ]),
    newNotification() {
      return this.userNotifications.some(
        (el) => !el.seen
      );
    },
    isHome() {
      return this.$route.name === "home";
    },
  },
  created() {
    this.loadNotifications();
    window.addEventListener("click", this.checkClick);
  },
  destroyed() {
    window.removeEventListener("click", this.checkCLick);
  },
  methods: {
    checkClick(event) {
      const clickOnLogin = event.composedPath().some(
        (el) => el.id === "header-login"
      );
      if (!clickOnLogin) {
        this.dropdownShown = false;
      }
    },
    toggleDropdown() {
      this.dropdownShown = !this.dropdownShown;
    },
    async loadNotifications() {
      const notifications = await getNotifications();
      this.userNotifications = notifications.data;
    },
    logout() {
      this.$store.dispatch('logout');
      if (!this.isHome) {
        this.$router.push("/");
      } else {
        console.log('send reset')
        EventBus.$emit("reset");
      }
      getGeolocation();
      logout();
    },
  },
};
</script>

<style lang="scss">
.header-profile-alert {
  width: 10px;
  height: 10px;
  margin-bottom: 3px;
}

.header-profile-dropdown {
  width: 400px;
  background: #fff;
  position: absolute;
  top: var(--header-height-desktop);
  right: 0px;
  border: 1px solid var(--border-gray);
  box-shadow: var(--shadow-large);
  color: #212529; /* same color as bootstrap's dropdown content */
}

.header-profile-notif-list {
  max-height: 110px;
  overflow-y: scroll;
  padding: 15px 0 0 15px;
  margin-bottom: 15px;
}

.header-profile-notif-item {
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
}

.header-profile-notif-dot {
  min-width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--green-login);
  margin: 4px 15px 0 0;
}

.header-profile-notif-content {
  font-size: 0.8rem;

  .bold {
    font-weight: bold;
  }
}

.header-profile-item {
  text-transform: uppercase;
  font-weight: 600;
  color: var(--dark-gray);
  cursor: pointer;
  border-top: 1px solid var(--border-gray);
  padding: 15px;
}

.header-profile-item:hover {
  background: var(--white-hover);
}

@media only screen and (max-width: 992px) {
  .header-profile-dropdown {
    left: 0px;
    right: 0px;
    width: unset;
  }
}
</style>
