<template>
  <div class="login-page">
    <div class="left-part">
      <LoginForm
        v-if="isLoginForm"
        @toggleForm="toggleForm"
      />
      <SignupForm
        v-else
        @toggleForm="toggleForm"
      />
    </div>
    <div class="right-part"></div>
  </div>
</template>

<script>
import LoginForm from "../components/Login/LoginForm";
import SignupForm from "../components/Login/SignupForm";

export default {
  name: "Login",
  components: {
    LoginForm,
    SignupForm,
  },
  data() {
    return {
      isLoginForm: true,
    };
  },
  methods: {
    toggleForm() {
      this.isLoginForm = !this.isLoginForm;
    },
  },
};
</script>

<style lang="scss">
.login-page {
  height: 100vh;
  display: flex;

  .left-part {
    background: var(--green-login-bg);

    .form-ctn {
      display: flex;
      flex-direction: column;
      margin: auto;
      padding-top: 30px;
      padding-bottom: 30px;

      .logo {
        width: 130px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10%;
      }

      h2 {
        color: var(--green-login);
        font-size: 2.2rem;
        font-weight: 900;
        margin-bottom: 10%;
        text-align: center;
      }

      form {
        align-self: stretch;
        display: flex;
        flex-direction: column;

        label {
          text-transform: uppercase;
          font-weight: 600;
          font-size: 0.9rem;
          color: var(--grey-text-login);
          margin-bottom: 0;
          letter-spacing: 1px;
        }

        input {
          background: #fff;
          border: 1px solid var(--border-gray);
          border-radius: var(--sm-radius);
          padding: 7px 10px;
        }

        input:not(:last-of-type) {
          margin-bottom: 4%;
        }

        .error-message {
          color: var(--red);
          margin-top: 10px;
          font-size: 0.8em;
        }

        button {
          background: var(--green-login);
          color: white;
          font-family: inherit;
          border-radius: var(--lg-radius);
          border: none;
          padding: 10px 0;
          font-weight: 600;
          letter-spacing: 1px;
          margin: 30px 0 0;

          &:active,
          &:focus {
            outline-width: 0;
          }
        }
      }

      hr {
        border: 0;
        clear: both;
        display: block;
        width: 95%;
        background-color: rgb(213, 213, 213);
        height: 1px;
        margin: 10% 0;
      }
    }

    .no-account-ctn {
      text-align: center;
      color: #8a8a8a;
      letter-spacing: 1px;

      .question {
        margin-bottom: 10px;
      }

      .sign-up-link {
        color: var(--dark-green);
        font-weight: 600;
        cursor: pointer;
      }
    }
  }

  @media screen and (min-width: 992px) {
    .left-part {
      display: flex;
      width: 40%;
      overflow-y: scroll;
      overflow-x: hidden;

      .form-ctn {
        width: 55%;

        h2 {
          font-size: 2.2rem;
        }
      }
    }

    .right-part {
      width: 60%;
      background-image: url("../../public/login/louvre.jpg");
      background-size: cover;
      display: relative;
    }
  }

  @media screen and (max-width: 992px) {
    .left-part {
      display: table;
      width: 100%;

      .form-ctn {
        width: 80%;

        h2 {
          font-size: 1.7rem;
        }
      }
    }

    .right-part {
      display: none;
    }
  }
}
</style>
