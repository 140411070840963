<template>
  <div class="add-place">
    <div class="icon-ctn" @click="pickTodoLocation('poi')">
      <AddPoiIcon />
    </div>
    <div class="icon-ctn" @click="pickTodoLocation('event')">
      <AddEventIcon />
    </div>
  </div>
</template>

<script>
import AddEventIcon from "../../assets/icons/add-event-icon.svg";
import AddPoiIcon from "../../assets/icons/add-poi-icon.svg";

export default {
  name: "MapAddIcons",
  components: {
    AddEventIcon,
    AddPoiIcon,
  },
  methods: {
    pickTodoLocation(type) {
      this.$emit('pickTodoLocation', type);
    },
  },
};
</script>

<style lang="scss">
.add-place {
  position: absolute;
  bottom: 30px;
  left: 20px;
  z-index: 1001;
  display: flex;
  align-content: center;

  .icon-ctn {
    background: white;
    padding: 10px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: var(--shadow-large);
    cursor: pointer;
  }

  .icon-ctn:first-child {
    margin-right: 10px;
  }
}
</style>
