import "leaflet/dist/leaflet.css";

import Snotify, { SnotifyPosition } from "vue-snotify";
import "vue-snotify/styles/material.css";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/fr";

import BootstrapVue from "bootstrap-vue";
import 'bootstrap/dist/css/bootstrap.css'

import './assets/css/style.css';

import Vue from "vue";
import store from "./store";
import App from "./App.vue";
import router from "./router/index";

Vue.use(BootstrapVue);

Vue.use(ElementUI, { locale });

const snotifyOptions = {
  toast: {
    position: SnotifyPosition.centerCenter,
    showProgressBar: true,
    pauseOnHover: true,
    timeout: 4000,
  },
};
Vue.use(Snotify, snotifyOptions);

Vue.config.productionTip = false;

const vm = new Vue({
  render: (h) => h(App),
  store,
  router,
}).$mount("#app");

export default vm;
