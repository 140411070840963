<template>
  <div
    id="header-suggestions"
    class="header-suggestions"
    v-if="suggestionsAvailable"
  >
    <div
      class="suggestion-menu-category interest-title"
      v-if="todoSuggestionsAvailable"
    >
      <PinIcon class="category-icon" />
      A FAIRE :
    </div>
    <div
      v-for="todoSuggestion in todoSuggestions"
      :key="todoSuggestion.id"
      class="suggestion-item"
      @click="selectTodoSuggestion()"
    >
      <CalIcon v-if="todoSuggestion.type === 'event'" />
      <span v-html="highlightExactMatch(todoSuggestion.name, searchQuery)"></span>
    </div>
    <div
      class="suggestion-menu-category addresses-title"
      v-if="ggSuggestionsAvailable"
    >
      <img src="../../../public/map/pos.png" alt="pos-icon" />
      ADRESSES :
    </div>
    <div
      v-for="ggSuggestion in ggSuggestions"
      :key="ggSuggestion.description"
      class="suggestion-item"
      @click="selectGgSuggestion(ggSuggestion)"
      v-html="highlightExactMatch(ggSuggestion.description, searchQuery)"
    >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { EventBus } from "../../event-bus.js";
import { highlightExactMatch, showConnectionRequired, showUnderDevelopment } from "../../utils.js";
import { getAddressDetails } from "../../service/api.js";

import PinIcon from "../../../public/map/pin.svg";
import CalIcon from "../../assets/icons/calendar-icon.svg";

export default {
  name: "HeaderSuggestions",
  data() {
    return {
      ggSuggestions: [],
      todoSuggestions: [],
      searchQuery: '',
    };
  },
  components: {
    CalIcon,
    PinIcon,
  },
  created() {
    EventBus.$on("newHeaderPredictions", this.loadSuggestions);
  },
  beforeDestroy() {
    EventBus.$off("newHeaderPredictions");
  },
  computed: {
    ...mapGetters([
      'logged',
    ]),
    ggSuggestionsAvailable() {
      return this.ggSuggestions.length > 0;
    },
    todoSuggestionsAvailable() {
      return this.todoSuggestions.length > 0;
    },
    suggestionsAvailable() {
      return this.ggSuggestionsAvailable || this.todoSuggestionsAvailable;
    },
  },
  methods: {
    highlightExactMatch: highlightExactMatch,
    resetSuggestions() {
      this.ggSuggestions = [];
      this.todoSuggestions = [];
      this.searchQuery = '';
    },
    closeSuggestions(event) {
      // Close suggestions only if click target is not on suggestions
      const clickOnSuggestions = event.composedPath().some(
        (el) => el.className === "header-suggestions"
      );
      const clickOnInput = event.composedPath().some(
        (el) => el.id === "header-search-input"
      );
      if (!clickOnSuggestions && !clickOnInput) this.resetSuggestions();
    },
    async selectGgSuggestion(ggSuggestion) {
      if (this.logged) {
        // Emit prediction selected to update user position
        const place_id = ggSuggestion.place_id;
        let payload = {};
        payload.address = ggSuggestion.description;
        this.resetSuggestions();
        console.log(place_id);
        const addressDetails = await getAddressDetails(place_id);
        console.log(addressDetails);
        payload.latitude = addressDetails.data.result.geometry.location.lat;
        payload.longitude = addressDetails.data.result.geometry.location.lng;
        EventBus.$emit("referenceSelected", payload);
      } else {
        showConnectionRequired();
      }
    },
    async selectTodoSuggestion() {
      if (this.logged) {
        showUnderDevelopment();
      } else {
        showConnectionRequired();
      }
    },
    loadSuggestions(payload) {
      // Save search query
      if (payload !== null) {
        this.ggSuggestions = payload.addresses.slice(0, 2);
        this.todoSuggestions = payload.todos.slice(0, 2);
        this.searchQuery = payload.searchQuery;
      } else {
        this.resetSuggestions();
      }
    },
  },
};
</script>

<style lang="scss">
.header-suggestions {
  width: 400px;
  background: #fff;
  position: absolute;
  top: var(--header-height-desktop);
  border: 1px solid var(--border-gray);
  box-shadow: var(--shadow-large);
  color: #212529; /* same color as bootstrap's dropdown content */
  padding: 15px;
}

.suggestion-menu-category {
  align-items: center;
  width: 100%;
  padding: 10px;
  cursor: auto;
  font-weight: 600;

  .category-icon {
    width: 20px;
    margin-right: 5px;
  }
}

.interest-title {
  border-bottom: 1px solid var(--border-gray);
  color: var(--orange);
}

.addresses-title {
  border-bottom: 1px solid var(--border-gray);
  color: var(--dark-green);
  margin-top: 5px;

  img {
    width: 25px;
    height: 25px;
    margin-right: 5px;
  }
}

.suggestion-item {
  width: 100%;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background: var(--bg-gray);
  }

  svg {
    height: 13px;
    margin-right: 5px;
    width: 20px;

    path {
      fill: var(--orange);
    }
  }
}

@media only screen and (max-width: 992px) {
  .header-suggestions {
    left: 0px;
    right: 0px;
    top: calc(var(--header-height-desktop) + 38px + 10px);
    width: unset;
  }
}
</style>
