<template>
  <div class="form-ctn login-form">
    <img src="../../assets/logo.png" alt="xplo-logo" class="logo" />
    <h2>Connexion</h2>
    <form onsubmit="return false">
      <label for="email">Adresse mail</label>
      <input type="email" id="email" v-model="email" />
      <label for="password">Mot de passe</label>
      <input type="password" id="password" v-model="password" />
      <a href="#" class="forgotten-password-link">Mot de passe oublié</a>
      <span v-if="errorMessageVisible" class="error-message"
        >Adresse mail et/ou mot de passe invalides</span
      >
      <button @click="login">Je me connecte</button>
    </form>
    <hr />
    <div class="no-account-ctn">
      <div class="question">Pas encore de compte?</div>
      <span class="sign-up-link" @click="emitToggleForm"
        >Inscrivez-vous maintenant!</span
      >
    </div>
  </div>
</template>

<script>
import { login } from "../../service/api.js";

export default {
  name: "LoginForm",
  data() {
    return {
      email: undefined,
      errorMessageVisible: false,
      password: undefined,
    };
  },
  methods: {
    login() {
      login(this.email, this.password)
        .then(() => {
          this.$store.dispatch('login');
          this.$router.push("/");
          this.$store.dispatch('synchronizeUser');
        })
        .catch((err) => {
          this.errorMessageVisible = true;
          console.error(err);
        });
    },
    emitToggleForm() {
      this.$emit("toggleForm");
    },
  },
};
</script>

<style lang="scss" scoped>
  .forgotten-password-link {
    font-size: 0.7rem;
    text-decoration: underline;
    cursor: pointer;
    color: var(--grey-text-login);
    margin-top: 5px;
  }
</style>
