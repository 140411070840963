var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"action-icons-ctn"},[_c('div',{staticClass:"left-icons-ctn"},[_c('LikeIcon',{class:[
        'likedislike-icon',
        {
          'rated-icon': _vm.image.liked,
        } ],on:{"click":function($event){return _vm.rateImage('like')}}}),_c('LikeIcon',{class:[
        'likedislike-icon',
        'dislike-icon',
        {
          'rated-icon': _vm.image.disliked,
        } ],on:{"click":function($event){return _vm.rateImage('dislike')}}})],1),_c('div',{staticClass:"add-icon-ctn"},[_c('PlusIcon',{staticClass:"add-icon",on:{"click":_vm.showAddImageSection}})],1),_c('div',[_c('CrossIcon',{staticClass:"cross-icon",on:{"click":_vm.closeFullscreenImage}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }