<template>
  <div class="action-icons-ctn">
    <div class="left-icons-ctn">
      <LikeIcon
        @click="rateImage('like')"
        :class="[
          'likedislike-icon',
          {
            'rated-icon': image.liked,
          },
        ]"
      />
      <LikeIcon
        @click="rateImage('dislike')"
        :class="[
          'likedislike-icon',
          'dislike-icon',
          {
            'rated-icon': image.disliked,
          },
        ]"
      />
    </div>
    <div class="add-icon-ctn">
      <PlusIcon
        class="add-icon"
        @click="showAddImageSection"
      />
    </div>
    <div>
      <CrossIcon
        class="cross-icon"
        @click="closeFullscreenImage"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { EventBus } from "../../event-bus";
import { showConnectionRequired, showUnderDevelopment } from "../../utils";

import LikeIcon from "../../assets/icons/like-icon.svg";
import PlusIcon from "../../assets/icons/plus-icon.svg";
import CrossIcon from "../../assets/icons/cross-icon.svg";

export default {
  name: "SliderActions",
  components: {
    LikeIcon,
    PlusIcon,
    CrossIcon,
  },
  props: {
    image: Object,
    imageNumber: Number,
  },
  computed: {
    ...mapGetters([
      'logged',
    ]),
  },
  methods: {
    rateImage(type) {
      if (this.logged) {
        EventBus.$emit("rateImage", this.imageNumber, type);
      } else {
        showConnectionRequired();
      }
    },
    showAddImageSection() {
      console.log(this.logged)
      if (this.logged) {
        showUnderDevelopment();
      } else {
        showConnectionRequired();
      }
    },
    closeFullscreenImage() {
      EventBus.$emit("closeFullscreenImage");
    },
  },
};
</script>

<style lang="scss" scoped>
.action-icons-ctn {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  z-index: 3000;

  div {
    background: rgba(13, 13, 13, 0.75);
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: var(--sm-radius);
    box-shadow: var(--shadow-large);

    svg {
      cursor: pointer;
    }
  }
}

.left-icons-ctn {
  width: 150px;
}

.add-icon-ctn {
  margin: 0 5px;
}

.add-icon {
  width: 17px;
  height: 17px;
}

.cross-icon {
  width: 17px;
  height: 17px;
  padding: 2px;
}

.likedislike-icon {
  width: 19px;
  height: 17px;
  path {
    fill: var(--medium-gray);
  }
}

.add-icon,.cross-icon,.rated-icon,.likedislike-icon:hover {
  path {
    fill: white;
  }
}

.rated-icon,.likedislike-icon:hover {
  path {
    stroke: white;
  }
}

.dislike-icon {
  transform: rotate(180deg);
}

</style>
