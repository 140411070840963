<template>
  <b-nav-form id="header-search" class="form-search header-search" form-class="w-100">
    <b-input-group class="w-100">
      <b-input-group-prepend>
        <b-button @click="openInput"><SearchIcon /></b-button>
      </b-input-group-prepend>
      <b-form-input id="header-search-input" ref="header-search-input" v-model="searchQuery" placeholder="Rechercher"></b-form-input>
    </b-input-group>
  </b-nav-form>
</template>

<script>
import { EventBus } from "../../event-bus";
import SearchIcon from "../../assets/icons/search-icon.svg";
import { gsap } from "gsap";
import { getSearchTodos, getSearchAddresses } from "../../service/api.js";

export default {
  name: "SearchInput",
  components: {
    SearchIcon,
  },
  data() {
    return {
      inputMustBeClosed: false,
      searchQuery: '',
    };
  },
  created() {
    window.addEventListener("resize", this.closeInput);
    window.addEventListener("click", this.checkClick);
  },
  destroyed() {
    window.removeEventListener("resize", this.closeInput);
    window.removeEventListener("click", this.checkClick);
  },
  watch: {
    searchQuery() {
      this.emitPredictions();
    },
  },
  methods: {
    openInput() {
      this.$refs["header-search-input"].focus();
      if (this.$refs["header-search-input"].$el.clientWidth === 0) {
        gsap.to(this.$refs["header-search-input"].$el, {
          duration: 0.3,
          width: 200,
        });
        this.inputMustBeClosed = true;
      }
    },
    checkClick(event) {
      const clickOnSearchArea = event.composedPath().some(
        (el) => el.id === "header-search" || el.id === "header-suggestions"
      );
      if (!clickOnSearchArea) {
        this.closeInput();
      }
    },
    closeInput() {
      if (this.inputMustBeClosed) {
        gsap.to(this.$refs["header-search-input"].$el, {
          duration: 0.3,
          width: 0,
        });
        this.inputMustBeClosed = false;
      }
      this.searchQuery = '';
    },
    async emitPredictions() {
      let payload;
      const searchQuery = this.searchQuery;
      if (searchQuery !== '') {
        const [todos, addresses] = await Promise.all([getSearchTodos(searchQuery), getSearchAddresses(this.searchQuery)]);
        payload = {
          todos: todos.data,
          addresses: addresses.data.predictions,
          searchQuery: searchQuery,
        };
      } else {
        payload = null;
      }
      if (searchQuery === this.searchQuery) {
        EventBus.$emit("newHeaderPredictions", payload);
      }
    },
  },
};
</script>
