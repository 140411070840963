<template>
  <div class="modal-outer-ctn">
    <div class="contributors-inner-ctn">
      <h3>Contributeurs</h3>
      <div>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam
        possimus quibusdam inventore nemo iste reiciendis velit aspernatur aut
        tempore earum, consequuntur perferendis nam ratione corrupti
        voluptatibus exercitationem ipsa. Temporibus, repudiandae.
        <br />
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam
        possimus quibusdam inventore nemo iste reiciendis velit aspernatur aut
        tempore earum, consequuntur perferendis nam ratione corrupti
        voluptatibus exercitationem ipsa. Temporibus, repudiandae.
      </div>
      <CrossIcon class="cross-icon" @click="emitCloseContributors" />
    </div>
  </div>
</template>

<script>
import CrossIcon from "../../assets/icons/cross-icon.svg";

export default {
  name: "Contributors",
  components: {
    CrossIcon,
  },
  methods: {
    emitCloseContributors() {
      this.$emit("closeContributors");
    },
  },
};
</script>

<style lang="scss">
.modal-outer-ctn {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 200001;

  .contributors-inner-ctn {
    background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
    min-height: 250px;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border-radius: var(--sm-radius);
    box-shadow: var(--shadow-large);
    text-shadow: none;
    padding: 30px;
    position: relative;

    h3 {
      margin-bottom: 40px;
    }

    .cross-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 20px;
      cursor: pointer;
      background: var(--red);
      border-radius: 50%;
      padding: 3px;

      path {
        fill: #fff;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .contributors-inner-ctn {
    padding: 45px;
    width: 95vw;
  }
}
</style>
