<template>
  <div>
    <FullscreenCarousel
      v-if="fullscreenImageVisible"
      :initialImageNumber="fullscreenInitialImageNumber"
      :images="todo.images"
    />
    <Header />
    <main class="container-fluid container-outer">
      <div class="row container-inner">
        <section class="col-lg-6 content-section left-section">
          <Map
            :todoId="todo.id"
            :todoLocation="todo.location"
            :todoIsEvent="todo.isEvent"
            :todoLiked="todo.liked"
            :todoDisliked="todo.disliked"
            :todoArray="todoArray"
          />
          <div class="swiper-map-container">
            <SmallSlider
              :images="smallSliderImages"
              position="map"
            />
          </div>
        </section>
        <AddTodoForm
          v-if="addTodoFormVisible"
          :newTodoType="newTodoType"
          :newTodoLocation="newTodoLocation"
        />
        <Description
          v-else
          :todoId="todo.id"
          :poiId="todo.poiId"
          :isEvent="todo.isEvent"
          :name="todo.name"
          :description="todo.description"
          :liked="todo.liked"
          :disliked="todo.disliked"
          :done="todo.done"
          :images="todo.images"
          :smallSliderImages="smallSliderImages"
          :stars="todo.stars"
          :reviews="todo.reviews"
          :didYouKnows="todo.didYouKnows"
          :startDate="todo.startDate"
          :endDate="todo.endDate"
          :address="todo.address"
          :website="todo.website"
          :previousBtnDisabled="previousBtnDisabled"
          :nextBtnDisabled="nextBtnDisabled"
        />
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Description from "../components/Description/Description";
import Header from "../components/Header/Header";
import Map from "../components/Map/Map";
import SmallSlider from "../components/Slider/SmallSlider";
import FullscreenCarousel from "../components/Slider/FullscreenCarousel";
import AddTodoForm from "../components/General/AddTodoForm";
import {
  getNextTodo,
  getTodoById,
  getAnswers,
  getArroundTodos,
  rateTodo,
  setTodoAsDone,
  getPreviousTodo,
  ratePhoto
} from "../service/api.js";
import { EventBus } from "../event-bus";

export default {
  name: "Home",
  components: {
    Description,
    Header,
    Map,
    SmallSlider,
    FullscreenCarousel,
    AddTodoForm
  },
  data() {
    return {
      todo: {
        id: 0,
        poiId: 0,
        validated: true,
        name: "",
        description: "",
        liked: false,
        disliked: false,
        done: false,
        location: [],
        images: [],
        stars: 0,
        reviews: 0,
        didYouKnows: [],
        startDate: new Date(),
        endDate: new Date(),
        address: "",
        website: ""
      },
      previousTodoId: null,
      nextTodoId: null,
      todoArray: [],
      addTodoFormVisible: false,
      newTodoType: '',
      newTodoLocation: [],
      fullscreenImageVisible: false,
      fullscreenInitialImageNumber: 0,
    };
  },
  computed: {
    ...mapGetters([
      'userLocation',
      'userLocationDefined',
      'distance',
    ]),
    isEvent() {
      return (this.todo.poiId === null);
    },
    previousBtnDisabled() {
      return (this.previousTodoId === null);
    },
    nextBtnDisabled() {
      return (this.nextTodoId === null);
    },
    isHome() {
      return this.$route.name === "home";
    },
    isHistory() {
      return this.$route.name === "history";
    },
    isDirectory() {
      return this.$route.name === "directory";
    },
    smallSliderImages() {
      let images;
      if (this.todo.id === 0) {
        images = [{ defaultImage: true }, { defaultImage: true }, { defaultImage: true }]
      } else {
        images = [...this.todo.images];
        if (images.length === 0)
          images.push(
            { addImage: true },
            { defaultImage: true },
            { defaultImage: true }
          );
        else if (images.length === 1) {
          images.push(
            { addImage: true },
            { defaultImage: true }
          );
        } else {
          images.push({ addImage: true });
        }
      }
      return images;
    }
  },
  created() {
    EventBus.$on("reset", this.resetData);
    EventBus.$on("loadPreviousTodo", this.loadPreviousTodo);
    EventBus.$on("loadNextTodo", this.loadNextTodo);
    EventBus.$on("loadTodoById", this.loadTodo);
    EventBus.$on("rateTodo", this.rateTodo);
    EventBus.$on("setTodoAsDone", this.setTodoAsDone);
    EventBus.$on("rateImage", this.rateImage);
    EventBus.$on("showAddTodoForm", this.showAddTodoForm);
    EventBus.$on("showFullscreenImage", this.showFullscreenImage);
    EventBus.$on("closeFullscreenImage", this.closeFullscreenImage);
    if (this.userLocationDefined) {
      console.log('created')
      this.initData();
    }
  },
  beforeDestroy() {  
    EventBus.$off("reset");
    EventBus.$off("loadPreviousTodo");
    EventBus.$off("loadNextTodo");
    EventBus.$off("loadTodoById");
    EventBus.$off("rateTodo");
    EventBus.$off("setTodoAsDone");
    EventBus.$off("rateImage");
    EventBus.$off("showAddTodoForm");
    EventBus.$off("showFullscreenImage");
    EventBus.$off("closeFullscreenImage");
  },
  watch: {
    userLocation() {
      if (this.userLocationDefined) {
        if (!this.todo.id) {
          this.initData();
        } else if (this.isHome) {
          this.initData();
          console.log('reload')
        }
      }
    },
    $route(){
      console.log('reset from route')
      this.resetData();
      if (this.userLocationDefined)
        this.initData();
    },
    'todo.id'(){
      this.updateNavigation();
    },
    todoArray(){
      this.updateNavigation();
    }
  },
  methods: {
    async initData() {
      if (this.isHome) {
        console.log('inithome')
        const next = await getNextTodo(null, this.distance);
        this.loadTodo(next.data.id);
      } else if (this.isHistory) {
        console.log('history')
        this.loadHistory();
      } else {
        this.loadDirectory();
      }
    },
    resetData() {
      console.log('resetting')
      this.addTodoFormVisible = false;
      this.todo.id = 0;
      this.todo.images = [];
      this.todoArray = [];
    },
    loadPreviousTodo() {
      EventBus.$emit("prepareTodoMarkerChange", this.todo);
      this.loadTodo(this.previousTodoId);
    },
    async loadNextTodo() {
      EventBus.$emit("prepareTodoMarkerChange", this.todo);
      this.loadTodo(this.nextTodoId);
    },
    async loadHistory() {
      const todoArray = await getAnswers();
      await this.loadTodo(todoArray.data[0].id);
      this.todoArray = todoArray.data;
    },
    async loadDirectory() {
      const todoArray = await getArroundTodos();
      await this.loadTodo(todoArray.data[0].id);
      this.todoArray = todoArray.data;
    },
    async loadTodo(todoId) {
      window.scrollTo(0,0);
      if (!this.isHome) {
        console.log('reset from loadtodo')
        this.resetData();
      }

      const todo = await getTodoById(todoId);

      if (todo.data.type === 'poi') {
        this.todo.poiId = todo.data.Poi.id;
        this.todo.name = todo.data.Poi.name;
        this.todo.description = todo.data.Poi.description;
        this.todo.location = [todo.data.Poi.latitude, todo.data.Poi.longitude];
        this.todo.stars = 5;
        this.todo.reviews = 100;
        this.todo.didYouKnows = todo.data.Poi.Didyouknows;
        this.todo.didYouKnows.forEach((didYouKnow) => {
          if (didYouKnow.DidyouknowRatings && didYouKnow.DidyouknowRatings.length > 0) {
            didYouKnow.liked = Boolean(didYouKnow.DidyouknowRatings[0].liked);
            didYouKnow.disliked = Boolean(didYouKnow.DidyouknowRatings[0].disliked);
          } else {
            didYouKnow.liked = false;
            didYouKnow.disliked = false;
          }
        });
        this.todo.startDate = new Date();
        this.todo.endDate = new Date();
        this.todo.address = '';
        this.todo.website = '';
      } else {
        this.todo.poiId = null;
        this.todo.name = todo.data.Event.name;
        this.todo.description = todo.data.Event.description;
        this.todo.location = [todo.data.Event.latitude, todo.data.Event.longitude];
        this.todo.stars = 0;
        this.todo.reviews = 0;
        this.todo.didYouKnows = [];
        this.todo.startDate = new Date(todo.data.Event.startDate);
        this.todo.endDate = new Date(todo.data.Event.endDate);
        this.todo.address = todo.data.Event.address;
        this.todo.website = todo.data.Event.website;
      }

      this.todo.validated = todo.data.validated;
      if (todo.data.TodoRatings && todo.data.TodoRatings.length > 0) {
        this.todo.liked = Boolean(todo.data.TodoRatings[0].liked);
        this.todo.disliked = Boolean(todo.data.TodoRatings[0].disliked);
      } else {
        this.todo.liked = false;
        this.todo.disliked = false;
      }
      if (todo.data.Dones && todo.data.Dones.length > 0) {
        this.todo.done = Boolean(todo.data.Dones[0].done);
      } else {
        this.todo.done = false;
      }

      this.todo.id = todo.data.id;

      this.todo.images = todo.data.Photos;
      this.todo.images.forEach((image) => {
        if (image.PhotoRatings && image.PhotoRatings.length > 0) {
          image.liked = Boolean(image.PhotoRatings[0].liked);
          image.disliked = Boolean(image.PhotoRatings[0].disliked);
        } else {
          image.liked = false;
          image.disliked = false;
        }
      });
      console.log(this.todo.images);
    },
    async updateNavigation() {
      if (this.todo.id) {
        if (this.isHome) {
          const previous = await getPreviousTodo(this.todo.id);
          this.previousTodoId = previous.data.id;
          const next = await getNextTodo(this.todo.id, this.distance);
          this.nextTodoId = next.data.id;
        } else if (this.isHistory && this.todoArray.length) {
          let i = 0;
          while (this.todoArray[i].id !== this.todo.id) {
            i++; 
          }
          console.log(i)
          if (i !== 0) {
            this.nextTodoId = this.todoArray[i-1].id
          } else {
            this.nextTodoId = null;
          }
          if (i !== this.todoArray.length-1) {
            this.previousTodoId = this.todoArray[i+1].id
          } else {
            this.previousTodoId = null;
          }
        }
      } else {
        this.previousTodoId = null;
        this.nextTodoId = null;
      }
    },
    async rateTodo(rating) {
      console.log({ todoId: this.todo.id, rating, pos: this.userLocation });
      this.todo.liked = (rating === true);
      this.todo.disliked = (rating === false);
      await rateTodo({ todoId: this.todo.id, rating, pos: this.userLocation });
      EventBus.$emit("synchronizeAnswersNumber");
    },
    setTodoAsDone(done) {
      this.todo.done = done;
      console.log('done2 '+this.todo.id);
      setTodoAsDone({ todoId: this.todo.id, done, pos: this.userLocation });
    },
    rateImage(imageNumber, type) {
      let newImage = {...this.todo.images[imageNumber]};
      let rating = null;
      if (type === 'like' && !newImage.liked) {
        rating = true;
      } else if (type === 'dislike' && !newImage.disliked) {
        rating = false;
      }

      newImage.liked = (rating === true);
      newImage.disliked = (rating === false);
      
      this.todo.images.splice(imageNumber, 1, newImage);
      ratePhoto(newImage.id, rating);
    },
    showAddTodoForm(payload) {
      const defaultValues = this.$options.data.apply(this);
      Object.assign(this.$data, {todo: defaultValues.todo, previousTodoId: defaultValues.previousTodoId, defaultValues: defaultValues.nextTodoId});
      this.newTodoType = payload.type;
      this.newTodoLocation = payload.location;
      this.addTodoFormVisible = true;
    },
    showFullscreenImage(imageIndex) {
      this.fullscreenInitialImageNumber = imageIndex;
      this.fullscreenImageVisible = true;
    },
    closeFullscreenImage() {
      this.fullscreenImageVisible = false;
    }
  },
};
</script>
