<template>
  <div class="action-btns">
    <button
      class="btn rounded shadow white action-btn like"
      :class="{'liked': liked}"
      @click="rate('like')"
    >
      <div
        class="action-btn-inner"
        :class="{
          'inner-like-grey': disliked,
          'inner-liked': liked,
          'inner-like': !liked && !disliked
        }"
      >
        <HeartIcon />
      </div>
    </button>
    <div class="already-visited-ctn">
      <input type="checkbox" name="already-visited" v-model="displayedDone" :checked="done"/>
      <label for="already-visited">Déjà visité</label>
    </div>
    <button
      class="btn rounded shadow white action-btn"
      :class="{'disliked': disliked}"
      @click="rate('dislike')"
    >
      <div
        class="action-btn-inner"
        :class="{
          'inner-dislike-grey': liked,
          'inner-disliked': disliked,
          'inner-dislike': !liked && !disliked
        }"
      >
        <CrossIcon />
      </div>
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { EventBus } from "../../event-bus";
import { showConnectionRequired } from "../../utils";

import HeartIcon from "../../assets/icons/heart-icon.svg";
import CrossIcon from "../../assets/icons/cross-icon.svg";

export default {
  name: "ActionButtons",
  components: {
    CrossIcon,
    HeartIcon,
  },
  props: {
    liked: { type: Boolean, default: false },
    disliked: { type: Boolean, default: false },
    done: { type: Boolean, default: false },
    todoId: { type: Number, default: 0 }
  },
  data() {
    return {
      displayedDone: this.done,
    };
  },
  computed: {
    ...mapGetters([
      'logged',
    ]),
    isHome() {
      return this.$route.name === "home";
    },
  },
  watch: {
    todoId(){
      this.displayedDone = this.done;
    },
    displayedDone() {
      this.setAsDone();
    }
  },
  methods: {
    rate(btnType) {
      if (this.logged) {
        let rating = null;
        if (btnType === "like" && !this.liked)
          rating = true;
        else if (btnType === "dislike" && !this.disliked)
          rating = false;
        EventBus.$emit("rateTodo", rating);

        if (this.isHome && rating !== null) 
          EventBus.$emit("loadNextTodo");
      } else {
        showConnectionRequired();
      }
    },
    setAsDone() {
      if (this.displayedDone != this.done) {
        if (this.logged) {
          console.log('done1 '+this.todoId)
          EventBus.$emit("setTodoAsDone", this.displayedDone);
        } else {
          showConnectionRequired();
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.action-btns {
  display: flex;
  align-items: center;
  z-index: 1000;
}

.action-btn:hover {
  background: white;
}

.action-btn-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: var(--transition);
}

.inner-like {
  background: var(--green-bg);
}

.inner-liked {
  border: 2px solid var(--green);
  background: var(--green-bg-hover);
}

.action-btn:hover .inner-like,.action-btn:hover .inner-like-grey {
  background: var(--green-bg-hover);
}

.inner-dislike {
  background: var(--red-bg);
}

.inner-disliked {
  border: 2px solid var(--red);
  background: var(--red-bg-hover);
}

.action-btn:hover .inner-dislike, .action-btn:hover .inner-dislike-grey {
  background: var(--red-bg-hover);
}

.inner-like-grey,.inner-dislike-grey {
  background: rgb(227, 227, 227);
}

.inner-like svg path,.inner-liked svg path,.action-btn:hover .inner-like-grey svg path {
  fill: var(--green);
}

.inner-dislike svg path,.inner-disliked svg path,.action-btn:hover .inner-dislike-grey svg path {
  fill: var(--red);
}

.inner-like-grey svg path,.inner-dislike-grey svg path {
  fill: var(--medium-gray);
}

.already-visited-ctn {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  font-weight: 600;
}

.already-visited-ctn label {
  margin: 0;
  text-align: center;
}

.already-visited-ctn input,
.already-visited-ctn label {
  cursor: pointer;
}

@media only screen and (min-width: 640px) {
  .action-btns {
    margin: 5% auto 0;
    padding: 0 20px;
    width: 380px;
    height: 105px;
    flex: 0 0 105px;
    background: white;
    box-shadow: var(--shadow-xlarge);
    border-radius: 60px;
  }

  .action-btn {
    width: 70px;
    height: 70px;
  }

  .action-btn-inner {
    width: 50px;
    height: 50px;
  }

  .like svg {
    height: 28px;
    width: 28px;
  }

  .already-visited-ctn {
    flex-direction: row;
  }

  .already-visited-ctn input {
    margin: 0 8px 0 0;
  }

  .already-visited-ctn label {
    font-size: 16px;
  }
}

@media only screen and (max-width: 640px) {
  .action-btns {
    bottom: 0;
    left: 50%;
    background: white;
    padding: 20px;
    margin: 0 0 0;
    border-top: 1px solid var(--border-gray);
  }

  .action-btn {
    width: 100px;
    height: 100px;
  }

  .action-btn-inner {
    width: 70px;
    height: 70px;
  }

  .like svg {
    height: 35px;
    width: 35px;
  }

  .already-visited-ctn input {
    margin: 0 0 5px;
  }

  .already-visited-ctn label {
    font-size: 14px;
  }
}


</style>
