<template>
  <div id="app" class="full-h">
    <router-view/>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { EventBus } from "./event-bus";
import { putReference } from "./service/api.js";

export default {
  name: "App",
  computed: {
    ...mapGetters([
      'userNameDefined',
      'userAddress',
    ]),
  },
  created() {
    EventBus.$on("referenceSelected", (payload) => {
      this.saveReferenceSelection(payload);
    });
    this.initUser();
  },
  beforeDestroy() {
    EventBus.$off("referenceSelected");
  },
  methods: {
    saveReferenceSelection(payload) {
      this.$store.dispatch("setUserLocation", [payload.latitude, payload.longitude]);
      this.$store.dispatch("setUserAddress", payload.address);
      putReference(payload.address, payload.latitude, payload.longitude);
    },
    async initUser() {
      await this.$store.dispatch('synchronizeUser');
    }
  },
};
</script>

<style lang="scss">
#app {
  // CHROME & EDGE

}
</style>
