<template>
  <div class="menu">
    <ul>
      <li
        v-for="item in menuItems"
        :key="item.label"
        @click="changeSection(item)"
      >
        <SuggestionsReceivedIcon v-if="item.type === 'suggReceived'" />
        <SuggestionsSentIcon v-else-if="item.type === 'suggSent'" />
        <Edit2Icon v-else-if="item.type === 'contributions'" />
        <SettingsIcon v-if="item.type === 'settings'" />
        <span>{{ item.label }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { showConnectionRequired, showUnderDevelopment } from "../../utils";

import Edit2Icon from "../../assets/icons/edit2-icon.svg";
import SettingsIcon from "../../assets/icons/settings-icon.svg";
import SuggestionsReceivedIcon from "../../assets/icons/suggestions-received-icon.svg";
import SuggestionsSentIcon from "../../assets/icons/suggestions-sent-icon.svg";

export default {
  name: "Menu",
  components: {
    Edit2Icon,
    SettingsIcon,
    SuggestionsReceivedIcon,
    SuggestionsSentIcon,
  },
  data() {
    return {
      menuItems: [
        {
          label: "Recommandations reçues",
          type: "suggReceived",
        },
        {
          label: "Recommandations envoyées",
          type: "suggSent",
        },
        {
          label: "Contributions à Xplo",
          type: "contributions",
        },
        {
          label: "Paramètres",
          type: "settings",
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'logged',
    ]),
  },
  methods: {
    changeSection(item) {
      if (item.type !== "contributions") {
        console.log(this.logged)
        if (this.logged) {
          showUnderDevelopment();
        } else {
          showConnectionRequired();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  width: 30%;
  background: var(--bg-gray);
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ul {
    list-style-type: none;
    margin: 50px 50px 0 0;

    li {
      display: flex;
      align-items: center;
      cursor: pointer;

      .temporary-icon {
        width: 25px;
        height: 25px;
        background: var(--gray);
        border-radius: 50%;
        margin-right: 20px;
      }

      svg {
        width: 25px;
        height: 25px;
        margin-right: 10px;

        path {
          fill: var(--gray);
          stroke: transparent;
        }
      }
    }

    li:not(:last-of-type) {
      margin-bottom: 40px;
    }
  }
}
</style>
