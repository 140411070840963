<template>
  <div class="map-search-menu">
    <div class="map-search-header">
      <span class="map-search-label uppercase">Point de référence :</span>
      <CrossIcon class="cross" @click="closeMapSearch" />
    </div>
    <MapSearchInput v-on:newPredictions="loadSuggestions" />
    <div v-if="ggSuggestionsNotAvailable">
      <span
        class="map-search-item"
        @click="getGeolocation"
      >
        <GeolocalizeIcon v-if="!isLoadingGeolocation" />
        <Spinner v-else class="spinner-icon" />
        Utiliser ma position actuelle
      </span>
      <span
        v-for="reference in referenceHistory"
        :key="reference.id"
        class="map-search-item"
        @click="selectPastReference(reference)"
        >
        <ReverseClockIcon />{{ reference.address }}
      </span>
    </div>
    <span
      v-for="ggSuggestion in ggSuggestions"
      :key="ggSuggestion.place_id"
      class="map-search-item block"
      @click="selectSuggestion(ggSuggestion)"
      v-html="highlightExactMatch(ggSuggestion.description, searchQuery)"
    >
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { EventBus } from "../../event-bus.js";
import { highlightExactMatch, getGeolocation, showConnectionRequired } from "../../utils.js";
import { getReferenceHistory, getAddressDetails } from "../../service/api.js";

import MapSearchInput from "../Map/MapSearchInput";
import Spinner from "../../assets/icons/Spinner";
import CrossIcon from "../../assets/icons/cross-icon.svg";
import GeolocalizeIcon from "../../assets/icons/geolocalize-icon.svg";
import ReverseClockIcon from "../../assets/icons/reverse-clock-icon.svg";

export default {
  name: "MapMenu",
  components: {
    CrossIcon,
    GeolocalizeIcon,
    MapSearchInput,
    ReverseClockIcon,
    Spinner,
  },
  data() {
    return {
      ggSuggestions: [],
      referenceHistory: [],
      searchQuery: '',
      isLoadingGeolocation: false,
    };
  },
  computed: {
    ...mapGetters([
      'logged',
    ]),
    ggSuggestionsNotAvailable() {
      return !this.ggSuggestions.length;
    },
  },
  created() {
    this.getReferenceHistory();
  },
  methods: {
    highlightExactMatch: highlightExactMatch,
    getGeolocation() {
      this.isLoadingGeolocation = true;
      getGeolocation();
    },
    async getReferenceHistory() {
      const referenceHistory = await getReferenceHistory();
      this.referenceHistory = referenceHistory.data.slice(0, 2);
    },
    async selectPastReference(reference) {
      let payload = {};
      payload.address = reference.address;
      payload.latitude = reference.latitude;
      payload.longitude = reference.longitude;
      EventBus.$emit("referenceSelected", payload);
    },
    resetSuggestions() {
      this.ggSuggestions = [];
      this.searchQuery = '';
    },
    closeMapSearch() {
      // Reset predictions before hiding
      this.resetSuggestions();
      // Signal to parent to hide menu
      this.$emit("closeMapSearch");
    },
    async selectSuggestion(ggSuggestion) {
      if (this.logged) {
        const place_id = ggSuggestion.place_id;
        let payload = {};
        payload.address = ggSuggestion.description;
        this.closeMapSearch();
        const addressDetails = await getAddressDetails(place_id);
        payload.latitude = addressDetails.data.result.geometry.location.lat;
        payload.longitude = addressDetails.data.result.geometry.location.lng;
        EventBus.$emit("referenceSelected", payload);
      } else {
        showConnectionRequired();
      }
    },
    loadSuggestions(payload) {
      // Only show predictions if user typed in search menu input
      if (payload !== null) {
        this.ggSuggestions = payload.addresses.slice(0, 3);
        this.searchQuery = payload.searchQuery;
      } else {
        this.resetSuggestions();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner-icon {
  margin-right: 10px;
}

.map-search-menu .block {
  display: block;
}
</style>
