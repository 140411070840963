<template>
  <div
    :class="[
      'map-wrapper',
      { 'map-wrapper-picking-location': pickingTodoLocation }
    ]"
  >
    <div
      v-if="mapInitiated && todoId"
      class="map-logo shadow"
      @click="showContributors"
    >
      <img
        src="/api/public/logos/1.svg"
        width="65px"
        alt=""
      />
    </div>
    <Contributors
      v-if="contributorsTextVisible"
      @closeContributors="closeContributors"
    />
    <transition name="fade">
      <MapSearchMenu
        v-if="mapSearchVisible"
        @closeMapSearch="closeMapSearch"
      />
    </transition>
    <!-- <DistanceDropdown v-if="isHome && mapInitiated" /> -->
    <FilterPoints v-if="isHistory && mapInitiated" />
    <MapAddIcons
      v-if="isDirectory && mapInitiated"
      @pickTodoLocation="pickTodoLocation"
    />
    <div id="map-div" class="map" />
  </div>
</template>

<script>
import L from "leaflet";
import "leaflet.markercluster";
import "leaflet-geometryutil";
import { EventBus } from "../../event-bus";
import { mapGetters } from 'vuex';

import MapSearchMenu from "./MapSearchMenu";
// import DistanceDropdown from "./DistanceDropdown";
import FilterPoints from "./FilterPoints";
import MapAddIcons from "./MapAddIcons";
import Contributors from "../General/Contributors";

export default {
  name: "Map",
  components: {
    MapAddIcons,
    Contributors,
    // DistanceDropdown,
    MapSearchMenu,
    FilterPoints,
  },
  props: {
    todoArray: { type: Array, default: () => [] },
    todoId: { type: Number, default: 0 },
    todoLocation: { type: Array, default: () => [] },
    todoIsEvent: { type: Boolean, default: false },
    todoLiked: { type: Boolean, default: false },
    todoDisliked: { type: Boolean, default: false },
  },
  data() {
    return {
      // Map
      map: null,
      mapInitiated: false,
      firstTodo: true,
      todoToChange: null,
      zoom: window.innerWidth < 640 ? 4 : 6,
      tileUrl:
        "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png",
      tileOptions: {
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attributions">CARTO</a>',
        maxZoom: 18,
        tileSize: 512,
        zoomOffset: -1,
        detectRetina: true,
      },
      contributorsTextVisible: false,
      animationDuration: 1.25,
      pickingTodoLocation: false,
      newTodotype: '',
      mapSearchVisible: false,
      // Markers and layers
      cluster: null,
      userMarker: null,
      todoMarker: null,
      radiusMarker: null,
      franceCenterPos: [46.81922900257082, 2.04658203125],
      // Icons
      pinIcon: L.icon({
        iconUrl: "map/pin.svg",
        iconSize: [32, 32],
        iconAnchor: [16, 32],
      }),
      pinIconLight: L.icon({
        iconUrl: "map/pinLight.svg",
        iconSize: [32, 32],
        iconAnchor: [16, 32],
      }),
      pinIconLike: L.icon({
        iconUrl: "map/pinLike.svg",
        iconSize: [32, 32],
        iconAnchor: [16, 32],
      }),
      pinIconDislike: L.icon({
        iconUrl: "map/pinDislike.svg",
        iconSize: [32, 32],
        iconAnchor: [16, 32],
      }),
      pinIconBigger: L.icon({
        iconUrl: "map/pin.svg",
        iconSize: [40, 40],
        iconAnchor: [20, 40],
      }),
      eventPinIcon: L.icon({
        iconUrl: "map/eventPin.svg",
        iconSize: [36, 36],
        iconAnchor: [18, 36],
      }),
      eventPinIconLight: L.icon({
        iconUrl: "map/eventPinLight.svg",
        iconSize: [36, 36],
        iconAnchor: [18, 36],
      }),
      eventPinIconLike: L.icon({
        iconUrl: "map/eventPinLike.svg",
        iconSize: [36, 36],
        iconAnchor: [18, 36],
      }),
      eventPinIconDislike: L.icon({
        iconUrl: "map/eventPinDislike.svg",
        iconSize: [36, 36],
        iconAnchor: [18, 36],
      }),
      eventPinIconBigger: L.icon({
        iconUrl: "map/eventPin.svg",
        iconSize: [40, 40],
        iconAnchor: [20, 40],
      }),
      posIcon: L.icon({
        iconUrl: "map/pos.png",
        iconSize: [36, 36],
        iconAnchor: [18, 18],
      }),
      // Circle
      circle: null,
      circleStyle: {
        color: "#499475",
        weight: 2,
        fill: false,
        dashArray: "16",
      }
    };
  },
  computed: {
    ...mapGetters([
      'userLocation',
      'userLocationDefined',
      'userAddress',
    ]),
    radius() {
      if (this.userMarker && this.todoMarker)
        return this.userMarker.getLatLng().distanceTo(this.todoMarker.getLatLng());
      else
        return 0;
    },
    displayedRadius() {
      return Math.round(this.radius/1000);
    },
    isHome() {
      return this.$route.name === "home";
    },
    isHistory() {
      return this.$route.name === "history";
    },
    isDirectory() {
      return this.$route.name === "directory";
    }
  },
  created() {
    EventBus.$on("prepareTodoMarkerChange", this.prepareTodoMarkerChange);
    EventBus.$on("reset", this.resetMap);
  },
  mounted() {
    this.initMap();
  },
  beforeDestroy() {
    EventBus.$off("prepareTodoMarkerChange");
    EventBus.$off("reset");
  },
  watch: {
    userLocation() {
      if (this.mapInitiated && this.userLocationDefined) {
        this.updateUserMarker();
      }
    },
    todoId() {
      if (this.mapInitiated) {
        if (this.isHome && this.todoId) {
          this.updateTodoMarker(this.todoLocation, this.todoIsEvent);
        } else if (this.isHistory && this.todoId && this.todoToChange !== null) {
          this.changeTodo();
        }
      }
    },
    todoArray() {
      if (this.mapInitiated && !this.isHome && this.todoArray.length)
        this.loadTodoArray();
    },
    $route(){
      this.resetMap();
    }
  },
  methods: {
    initMap() {
      this.cluster = null;
      this.userMarker = null;
      this.todoMarker = null;
      this.radiusMarker = null;
      this.circle = null;
      console.log('starting to init map')
      // Make Leaflet control map div
      this.map = L.map('map-div', { zoomControl: false }).setView(
        this.franceCenterPos,
        this.zoom
      );
      // Load tile
      L.tileLayer(this.tileUrl, this.tileOptions).addTo(this.map);
      this.map.whenReady(this.initMapStep2);
    },
    initMapStep2() {
      this.mapInitiated = true;
      // Set zoom control
      L.control.zoom({ position: "bottomright" }).addTo(this.map);
      this.map.on('zoomend', this.updateRadiusDisplay);
      this.map.on('unload', this.initMap);
      
      if (this.userLocationDefined) {
        this.updateUserMarker();
      }
      if (this.isHome && this.todoId) {
        this.updateTodoMarker(this.todoLocation, this.todoIsEvent);
      } else if (!this.isHome && this.todoArray.length > 0) {
        this.loadTodoArray();
      }
    },
    updateCircle() {
      if (this.circle === null) {
        // Add radius to circle style
        const circleStyle = this.circleStyle;
        circleStyle.radius = this.radius;
        // Add circle to map
        this.circle = L.circle(this.userLocation, circleStyle).addTo(this.map);
      } else {
        this.circle.setLatLng(this.userLocation);
        this.circle.setRadius(this.radius);
      }
      // Calculate the position of the radius indicator
      const angle =
        L.GeometryUtil.angle(
          this.map,
          this.userMarker.getLatLng(),
          this.todoMarker.getLatLng()
        ) + 35;
      const radiusLatLng = L.GeometryUtil.destination(
        this.userMarker.getLatLng(),
        angle,
        this.radius
      );
      // Create dynamic icon
      const radiusIcon = L.divIcon({
        className: "circle-radius-indicator",
        html: `${this.displayedRadius} km`,
        iconAnchor: [25, 10],
      });
      if (this.radiusMarker === null) {
        this.radiusMarker = L.marker(radiusLatLng, { icon: radiusIcon }).addTo(this.map);
      } else {
        this.radiusMarker.setLatLng(radiusLatLng);
        this.radiusMarker.setIcon(radiusIcon);
      }
    },
    updateRadiusDisplay() {
      if (this.radiusMarker !== null) {
        const userPixels = this.map.latLngToLayerPoint(this.userMarker.getLatLng());
        const todoPixels = this.map.latLngToLayerPoint(this.todoMarker.getLatLng());
        const pixels = userPixels.distanceTo(todoPixels);
        if (pixels < 100)
          this.removeLayer(this.radiusMarker)
        else if (!this.map.hasLayer(this.radiusMarker))
          this.radiusMarker.addTo(this.map);
      }
    },
    generateClusterMarker(todoId, todoLocation, todoIsEvent, todoLiked, todoDisliked) {
      let icon;
      if (todoIsEvent) {
        if (todoLiked)
          icon = this.eventPinIconLike;
        else if (todoDisliked)
          icon = this.eventPinIconDislike;
        else
          icon = this.eventPinIconLight;
      } else {
        if (todoLiked)
          icon = this.pinIconLike;
        else if (todoDisliked)
          icon = this.pinIconDislike;
        else
          icon = this.pinIconLight;
      }
      // Create marker
      const marker = L.marker(todoLocation, { icon });
      marker.on("click", this.handleClickOnTodo);
      marker.setZIndexOffset(-1000);
      marker.todoId = todoId;
      return marker;
    },
    async loadTodoArray() {
      console.log('testarray')
      this.updateTodoMarker(this.todoLocation, this.todoIsEvent);
      if (this.todoArray.length > 0) {
        console.log('load map')
        console.log(this.todoArray)
        // Reset history markers and location marker
        await this.removeLayer(this.cluster);
        this.cluster = L.markerClusterGroup({
          iconCreateFunction: (cluster) => {
            return L.divIcon({
              html: cluster.getChildCount(),
              className: "cluster-icon",
              iconSize: null,
            });
          },
        });
        // Place a marker on the map for each history point
        let markerArray = [];
        let marker;
        let locationArray = [this.userLocation];
        this.todoArray.forEach((todo) => {
          let todoLocation = [todo.latitude, todo.longitude];
          locationArray.push(todoLocation);
          let todoIsEvent = (todo.type === 'event');
          if ( todo.id !== this.todoId) {
            marker = this.generateClusterMarker(todo.id, todoLocation, todoIsEvent, todo.liked, todo.disliked);
            markerArray.push(marker);
          }
        });
        this.cluster.addLayers(markerArray);
        this.map.addLayer(this.cluster);
        const bounds = L.latLngBounds(locationArray).pad(0.25);
        this.map.flyToBounds(bounds, {
          duration: this.animationDuration,
        });
      }
    },
    animateMap() {
      if (this.isHome || !this.firstTodo) {
        // Set bounds
        const corner1 = this.userMarker.getLatLng();
        const corner2 = this.todoMarker.getLatLng();
        const bounds = L.latLngBounds(corner1, corner2).pad(0.25);
        // Launch flyToBounds animation
        this.map.flyToBounds(bounds, {
          duration: this.animationDuration,
        });
      }
      if (this.firstTodo)
        this.firstTodo = false;
    },
    displayUserTooltip(address) {
      const tooltipContent = (address === null)? 'Position actuelle':address;
      const currentTooltip = this.userMarker.getTooltip();
      if (currentTooltip === undefined) {
        const tooltipOptions = {
          direction: "bottom",
          offset: L.point(0, 15),
          className: "user-tooltip",
          permanent: true,
        };
        const userTooltip = L.tooltip(tooltipOptions).setContent(
          tooltipContent
        );
        this.userMarker.bindTooltip(userTooltip);
      } else {
        this.userMarker.setTooltipContent(tooltipContent);
      }
    },
    handleClickOnTodo(event) {
      if (!this.todoId) {
        this.$snotify.error("Page en cours de chargement", {
          timeout: 1000,
          showProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
        });
      } else {
        const prevTodoId = this.todoId;
        const prevTodoLocation = this.todoLocation;
        const prevTodoIsEvent = this.todoIsEvent;
        const prevTodoLiked = this.todoLiked;
        const prevTodoDisliked = this.todoDisliked;
        EventBus.$emit("loadTodoById", event.target.todoId);
        const clusterIconUrl = event.target.options.icon.options.iconUrl;
        this.cluster.removeLayer(event.target);
        let newIcon;
        if (clusterIconUrl === 'map/pinLight.svg' || clusterIconUrl === 'map/pinLike.svg' || clusterIconUrl === 'map/pinDislike.svg' ) {
          newIcon = this.pinIconBigger;
        } else {
          newIcon = this.eventPinIconBigger;
        }
        this.todoMarker.setLatLng(event.target.getLatLng());
        this.todoMarker.setIcon(newIcon);
        this.updateCircle();
        let marker = this.generateClusterMarker(prevTodoId, prevTodoLocation, prevTodoIsEvent, prevTodoLiked, prevTodoDisliked);
        this.cluster.addLayer(marker);
      }
    },
    prepareTodoMarkerChange(todo) {
      this.todoToChange = todo;
    },
    changeTodo() {
      const todoToChange = this.todoToChange
      this.todoToChange = null;
      console.log(this.todoId)
      const childMarkers = this.cluster.getLayers();
      let i = 0;
      console.log('début boucle')
      while (childMarkers[i].todoId !== this.todoId) {
        console.log(childMarkers[i].todoId)
        i++; 
      }
      this.cluster.removeLayer(childMarkers[i]);
      this.updateTodoMarker();
      let marker = this.generateClusterMarker(todoToChange.id, todoToChange.location, todoToChange.isEvent, todoToChange.liked, todoToChange.disliked);
      this.cluster.addLayer(marker);
    },
    async updateUserMarker() {
      this.mapSearchVisible = false;
      if (this.userMarker === null) {
        this.userMarker = await L.marker(this.userLocation, { icon: this.posIcon }).addTo(this.map);
        this.userMarker.on("click", this.showMapSearch);
      } else {
        this.userMarker.setLatLng(this.userLocation)
      }
      if (this.todoMarker !== null) {
        this.updateCircle();
        this.animateMap();
      }
      if (this.isHome)
        this.displayUserTooltip(this.userAddress);
    },
    async updateTodoMarker() {
      let icon;
      if (this.todoIsEvent) {
        if (this.isHome)
          icon = this.eventPinIcon;
        else
          icon = this.eventPinIconBigger
      } else {
        if (this.isHome)
          icon = this.pinIcon;
        else
          icon = this.pinIconBigger;
      }
      console.log('ttttttttttttttttttttest')
      if (this.todoMarker === null){
        console.log('todomarkernull')
        const marker = L.marker(this.todoLocation, { icon: icon });
        marker.setZIndexOffset(2000);
        this.todoMarker = marker.addTo(this.map);
      } else {
        console.log('todomarkernotnull')
        this.todoMarker.setLatLng(this.todoLocation);
        this.todoMarker.setIcon(icon);
      }
      if (this.userMarker !== null) {
        this.updateCircle();
        this.animateMap();
      }
    },
    removeLayer(marker) {
      if (marker && this.map.hasLayer(marker))
        this.map.removeLayer(marker);
    },
    pickTodoLocation(type) {
      this.pickingTodoLocation = true;
      L.DomUtil.addClass(this.map._container,'map-picking-location');
      this.newTodoType = type;
      this.map.on("click", this.handleClickOnMap);
    },
    handleClickOnMap(event) {
      // Remove border
      this.pickingTodoLocation = false;
      // Add marker
      const icon = this.newTodoType === "event" ? this.eventPinIconBigger : this.pinIconBigger;
      this.todoMarker.setLatLng([event.latlng.lat, event.latlng.lng]);
      this.todoMarker.setIcon(icon);
      // Update circle and radius indicator
      this.updateCircle();
      this.animateMap();
      // Show form
      const payload = {
        type: this.newTodoType,
        location: [event.latlng.lat, event.latlng.lng]
      };
      EventBus.$emit("showAddTodoForm", payload);
      // Remove click event-listener
    },
    showContributors() {
      this.contributorsTextVisible = true;
    },
    closeContributors() {
      this.contributorsTextVisible = false;
    },
    showMapSearch() {
      this.mapSearchVisible = true;
    },
    closeMapSearch() {
      this.mapSearchVisible = false;
    },
    resetMap() {
      console.log('starting to reset map')
      this.mapInitiated = false;
      
      this.map.remove();
    },
  },
};
</script>

<style lang="scss">
.fade-enter-active,.fade-leave-active {
  transition: all 0.25s ease-out;
}
.fade-enter,.fade-leave-to {
  opacity: 0;
  top: -10px;
  right: -10px;
}

.user-tooltip {
  font-family: "Montserrat", sans-serif;
}

.leaflet-control-zoom {
  a {
    color: var(--medium-gray) !important;
    line-height: 43px !important;
    font-size: 38px;
    font-weight: 100;
    font-family: "Montserrat", sans-serif;

    &:first-of-type {
      border-bottom: 1px solid var(--border-gray) !important;
    }
  }
}

.cluster-icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #cc9664;
  color: #fff;
  text-align: center;
  font-size: 20px;
  line-height: 40px;
  margin-top: -20px;
  margin-left: -20px;
}

.circle-radius-indicator {
  width: fit-content !important;
}

.map-wrapper-picking-location {
  border: 5px solid var(--orange);
}

.leaflet-container.map-picking-location {
  cursor: copy;
}
</style>
