<template>
  <button :id="id" :class="classes" class="button">
    <slot name="icon"></slot>
    <span v-if="text">{{ text }}</span>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    id: String,
    classes: String,
    text: { type: String, default: "" },
  },
};
</script>
