<template>
  <div class="content-didyouknows">
    <div
      class="content-didyouknows-header"
      :class="{ hidden: didYouKnowsHidden }"
    >
      <h2
        class="content-didyouknows-header-title bold"
        @click="toggleDidYouKnowsHidden"
      >
        <span>Le saviez-vous ?</span>
        <ArrowIcon />
      </h2>
      <Button
        :classes="
          'btn green outline bold uppercase content-add-didyouknows-btn'
        "
        :text="'Ajouter une anecdote'"
        :class="{ hidden: didYouKnowsHidden }"
        @click.native="addDidYouKnowInput"
      >
        <template v-slot:icon>
          <PlusIcon />
        </template>
      </Button>
    </div>

    <ul
      class="content-didyouknows-list"
      :class="{ hidden: didYouKnowsHidden }"
    >
      <li
        v-for="(didYouKnow, didYouKnowIndex) in displayedDidYouKnows"
        :key="didYouKnow.id"
        class="content-didyouknows-list-item"
      >
        <div v-if="didYouKnowIndex===newDidYouKnowIndex" class="new-didyouknow-input-ctn">
          <input
            v-model="newDidYouKnowText"
            @keyup.enter="submitDidYouKnow()"
            @keyup.escape="resetDidYouKnow()"
            ref="didYouKnowInput"
            autofocus
          />
        </div>
        <div v-else-if="!didYouKnow.validated" class="didyouknow-not-validated">
          <popper trigger="hover" :options="{ placement: 'top-start' }">
            <div class="popper">En cours de validation</div>
            <p
              slot="reference"
              class="content-didyouknows-list-item-text"
              @click="editDidYouKnow(didYouKnow.text, didYouKnowIndex)"
            >
              {{ didYouKnow.text }}
            </p>
          </popper>
          <EditIcon
            class="edit-icon"
            @click="editDidYouKnow(didYouKnow.text, didYouKnowIndex)"
          />
        </div>
        <div v-else class="didyouknow-validated">
          <p class="content-didyouknows-list-item-text">
            {{ didYouKnow.text }}
          </p>
          <div class="content-didyouknows-list-item-btns">
            <LikeIcon
              :class="[
                'content-didyouknows-list-item-btn',
                {
                  'content-didyouknows-list-item-btn-rated': didYouKnow.liked,
                },
              ]"
              @click="rateDidYouKnow(didYouKnowIndex, 'like')"
            />
            <LikeIcon
              :class="[
                  'content-didyouknows-list-item-btn',
                  'content-didyouknows-list-item-btn-dislike',
                  {
                    'content-didyouknows-list-item-btn-rated': didYouKnow.disliked,
                  },
                ]"
              @click="rateDidYouKnow(didYouKnowIndex, 'dislike')"
            />
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Popper from "vue-popperjs";
import "vue-popperjs/dist/vue-popper.css";
import { showConnectionRequired } from "../../utils";
import { rateDidYouKnow, updateDidYouKnow, addDidYouKnow } from "../../service/api.js";

import Button from "../General/Button";
import ArrowIcon from "../../assets/icons/arrow-icon.svg";
import EditIcon from "../../assets/icons/edit-icon.svg";
import LikeIcon from "../../assets/icons/like-icon.svg";
import PlusIcon from "../../assets/icons/plus-icon.svg";

export default {
  name: "DidYouKnows",
  components: {
    ArrowIcon,
    Button,
    EditIcon,
    LikeIcon,
    PlusIcon,
    popper: Popper,
  },
  props: {
    didYouKnows: Array,
    poiId: Number,
  },
  data() {
    return {
      displayedDidYouKnows: this.didYouKnows,
      didYouKnowsHidden: true,
      newDidYouKnowText: '',
      newDidYouKnowIndex: null,
    };
  },
  computed: {
    ...mapGetters([
      'logged',
    ]),
  },
  watch: {
    didYouKnows() {
      // Update displayed didYouKnows with new POI
      this.displayedDidYouKnows = this.didYouKnows;
    },
  },
  created() {
    window.addEventListener("click", this.checkClick);
  },
  destroyed() {
    window.removeEventListener("click", this.checkCLick);
  },
  methods: {
    checkClick(event) {
      const clickOnInput = event.composedPath().some(
        (el) => el.classList !== undefined &&  (el.classList.contains('new-didyouknow-input-ctn') || el.classList.contains('edit-icon') || el.classList.contains('content-add-didyouknows-btn'))
      );
      if (!clickOnInput) {
        this.submitDidYouKnow();
      }
    },
    addDidYouKnowInput() {
      if (this.logged) {
        this.submitDidYouKnow();
        this.displayedDidYouKnows.unshift({
          text: '',
          validated: false,
        });
        this.newDidYouKnowIndex = 0;
        this.$nextTick(function () {
          this.$refs["didYouKnowInput"][0].focus();
        });
      } else {
        showConnectionRequired();
      }
    },
    async addDidYouKnowOnServer(newDidYouKnowIndex, newDidYouKnowText) {
      let additionResult = await addDidYouKnow(this.poiId, newDidYouKnowText);
      console.log(additionResult.data.id);
      this.displayedDidYouKnows[newDidYouKnowIndex].id = additionResult.data.id;
    },
    submitDidYouKnow() {
      if (this.newDidYouKnowIndex !== null) {
        if ('id' in this.displayedDidYouKnows[this.newDidYouKnowIndex]) {
          console.log('update')
          updateDidYouKnow(this.displayedDidYouKnows[this.newDidYouKnowIndex].id, this.newDidYouKnowText);
        } else if (this.newDidYouKnowText !== '') {
          console.log('add dyk')
          this.addDidYouKnowOnServer(this.newDidYouKnowIndex, this.newDidYouKnowText)
        }

        if (this.newDidYouKnowText === '') {
          this.resetDidYouKnow()
        } else {
          let newDidYouKnow = this.displayedDidYouKnows[this.newDidYouKnowIndex];
          newDidYouKnow.text = this.newDidYouKnowText
          this.displayedDidYouKnows.splice(this.newDidYouKnowIndex, 1, newDidYouKnow);
          this.newDidYouKnowIndex = null;
          this.newDidYouKnowText = '';
        }
      }
    },
    resetDidYouKnow() {
      this.displayedDidYouKnows.splice(this.newDidYouKnowIndex, 1);
      this.newDidYouKnowIndex = null;
      this.newDidYouKnowText = '';
    },
    editDidYouKnow(text, index) {
      // Edit didyouknow
      this.submitDidYouKnow();
      this.newDidYouKnowText = text;
      this.newDidYouKnowIndex = index;
      this.$nextTick(function () {
        this.$refs["didYouKnowInput"][0].focus();
      });
    },
    toggleDidYouKnowsHidden() {
      this.didYouKnowsHidden = !this.didYouKnowsHidden;
    },
    rateDidYouKnow(didYouKnowIndex, type) {
      if (this.logged) {
        let newDidYouKnow = {...this.displayedDidYouKnows[didYouKnowIndex]};
        let rating = null;
        if (type === 'like' && !newDidYouKnow.liked) {
          rating = true;
        } else if (type === 'dislike' && !newDidYouKnow.disliked) {
          rating = false;
        }

        newDidYouKnow.liked = (rating === true);
        newDidYouKnow.disliked = (rating === false);
        
        this.displayedDidYouKnows.splice(didYouKnowIndex, 1, newDidYouKnow);
        rateDidYouKnow(newDidYouKnow.id, rating);
      } else {
        showConnectionRequired();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content-didyouknows {
  margin: 40px 0 25px 0;
  padding: 25px;
  background: var(--bg-gray);
  border-radius: var(--sm-radius);
}

.content-didyouknows-header {
  display: flex;
  flex-direction: column;
  margin: 0 0 20px;
}

.content-didyouknows-header-title {
  flex: 1;
  margin: 0 0 15px;

  span {
    letter-spacing: 0.05em;
  }

  svg {
    display: none;
  }
}

.content-add-didyouknows-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  letter-spacing: 0.05rem;

  svg {
    margin-right: 8px;
    height: 12px;
    width: 12px;
  }
}

.content-didyouknows-list {
  padding: 0;
}

.content-didyouknows-list-item {
  display: flex;
  align-items: center;
}

.content-didyouknows-list-item::before {
  content: "•";
  font-size: 20px;
  margin-right: 5px;
}

.content-didyouknows-list-item:not(:last-child) {
  margin: 0 0 15px;
}

.content-didyouknows-list-item-text {
  display: flex;
  flex: 1;
  margin: 0 20px 0 0;
  font-size: 13px;
  line-height: 1.6;
  text-align: justify;
  text-justify: inter-word;
}

.didyouknow-validated {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.content-didyouknows-list-item-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.content-didyouknows-list-item-btn {
  width: 20px;
  height:17px;
  cursor: pointer;
  transition: var(--transition);
}

.content-didyouknows-list-item-btn path {
  fill: var(--light-gray);
}

.content-didyouknows-list-item-btn:hover path {
  fill: black;
}

.content-didyouknows-list-item-btn:not(:last-child) {
  margin-right:10px;
}

.content-didyouknows-list-item-btn-dislike {
  transform: rotate(180deg);
}

.content-didyouknows-list-item-btn-rated path {
  fill: black;
}

.didyouknow-not-validated {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .content-didyouknows-list-item-text {
    font-weight: bold;
    cursor: pointer;
  }

  .edit-icon {
    width: 15px;
    margin-right: 12px;
    fill: var(--medium-gray);
    cursor: pointer;
  }
}

.new-didyouknow-input-ctn {
  width: 100%;
  font-size: 13px;
  padding: 5px 45px 5px 10px;
  background: #fff;
  border-radius: var(--sm-radius);
  border: 1px solid var(--border-gray);
  box-shadow: var(--shadow-medium);
  position: relative;

  input {
    width: 100%;
    border: none;
    background: transparent;
  }
}

.popper {
  box-shadow: var(--shadow-large);
  padding: 5px 10px;
  font-size: 13px;
  color: var(--dark-gray);
  background: #fff;
}

@media only screen and (max-width: 640px) {
  .content-didyouknows {
    padding: 20px;
  }
  .content-didyouknows-header-title {
    color: #54a985;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    cursor: pointer;
  }
  .content-didyouknows-header-title svg {
    display: flex;
    transform: rotate(90deg);
    height: 18px;
    width: 18px;
    margin-right: 8px;
    transition: var(--transition);
  }
  .content-didyouknows-header-title svg path {
    fill: #54a985;
  }
  .content-add-didyouknows-btn {
    margin-top: 15px;
  }
  .content-didyouknows-header.hidden {
    margin: 0;
  }
  .content-didyouknows-header:not(.hidden)
.content-didyouknows-header-title
svg {
    transform: rotate(-90deg);
  }
  .content-didyouknows-list.hidden,
  .content-add-didyouknows-btn.hidden {
    display: none;
  }
}

@media only screen and (min-width: 640px) {
  .content-didyouknows {
    display: block;
    margin: 40px 0 25px;
  }
  .content-didyouknows-header {
    flex-direction: row;
    align-items: center;
    margin: 0 0 30px;
  }
  .content-didyouknows-header-title {
    margin: 0;
  }
  .content-add-didyouknows-btn {
    justify-content: flex-start;
  }
  .content-didyouknows-list-item:not(:last-child) {
    margin: 0 0 25px;
  }
}
</style>
