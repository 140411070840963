<template>
  <b-input-group class="form-search">
    <b-input-group-prepend>
      <b-button><SearchIcon /></b-button>
    </b-input-group-prepend>
    <b-form-input v-model="searchQuery" placeholder="Adresse, ville, ..."></b-form-input>
  </b-input-group>
</template>

<script>
import SearchIcon from "../../assets/icons/search-icon.svg";
import { getSearchAddresses } from "../../service/api.js";

export default {
  name: "SearchInput",
  components: {
    SearchIcon,
  },
  data() {
    return {
      searchQuery: '',
    };
  },
  watch: {
    searchQuery() {
      this.emitPredictions();
    },
  },
  methods: {
    async emitPredictions() {
      let payload;
      const searchQuery = this.searchQuery;
      if (searchQuery !== '') {
        const addresses = await getSearchAddresses(this.searchQuery);
        payload = {
          addresses: addresses.data.predictions,
          searchQuery: searchQuery,
        };
      } else {
        payload = null;
      }
      if (searchQuery === this.searchQuery) {
        this.$emit("newPredictions", payload);
      }
    },
  },
};
</script>
