<template>
  <div class="nav-btns-ctn">
    <div
      :class="[
        'inner-ctn',
        'previous',
        { 'grey-disabled': previousBtnDisabled }
      ]"
      @click="loadPreviousTodo"
    >
      <Popper :options="{ placement: 'top-center' }">
        <div class="popper">Précédent</div>
        <PreviousIcon slot="reference"/>
      </Popper>
      <div class="btn-label">
        Précédent
      </div>
    </div>
    <div
      :class="[
        'inner-ctn',
        'next',
        { 'grey-disabled': nextBtnDisabled }
      ]"
      class="inner-ctn next"
      @click="loadNextTodo"
    >
      <div class="btn-label">
        Suivant
      </div>
      <Popper :options="{ placement: 'top-center' }">
        <div class="popper">Suivant</div>
        <NextIcon slot="reference"/>
      </Popper>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { EventBus } from "../../event-bus";
import Popper from "vue-popperjs";
import "vue-popperjs/dist/vue-popper.css";
import { showConnectionRequired } from "../../utils";

import PreviousIcon from "../../assets/icons/previous-icon.svg";
import NextIcon from "../../assets/icons/next-icon.svg";

export default {
  name: "PreviousNextButtons",
  props: {
    previousBtnDisabled: { type: Boolean, default: true },
    nextBtnDisabled: { type: Boolean, default: true },
  },
  components: {
    Popper,
    PreviousIcon,
    NextIcon,
  },
  computed: {
    ...mapGetters([
      'logged',
    ]),
  },
  methods: {
    loadPreviousTodo() {
      if (!this.previousBtnDisabled) {
        if (this.logged) {
          EventBus.$emit("loadPreviousTodo");
        } else {
          showConnectionRequired();
        }
      }
    },
    loadNextTodo() {
      if (!this.nextBtnDisabled) {
        if (this.logged) {
          EventBus.$emit("loadNextTodo");
        } else {
        showConnectionRequired();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.popper {
  box-shadow: var(--shadow-large);
  padding: 5px 10px;
  font-size: 13px;
  color: var(--dark-gray);
  background: #fff;
}

.nav-btns-ctn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inner-ctn {
  cursor: pointer;

  svg {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 2px solid var(--green);
    padding: 5px;

    path {
      fill: var(--green);
    }
  }

  svg:hover {
    border-color: var(--green-hover);

    path {
      fill: var(--green-hover);
    }
  }

  .btn-label {
    text-transform: uppercase;
  }
}

.previous svg {
  margin-right: 10px;
}

.next svg {
  margin-left: 10px;
}

.inner-ctn.grey-disabled {
  cursor: default;

  svg {
    border: 2px solid #ebebeb;

    path {
      fill: #ebebeb;
    }
  }

  .btn-label {
    color: #ebebeb;
  }

  .popper {
    color: #ebebeb;
  }
}

@media screen and (min-width: 640px) {
  .nav-btns-ctn {
    width: 75%;
    min-width: 500px;
    position: absolute;
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%);
  }

  .btn-label {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .nav-btns-ctn {
    width: 100%;
    padding: 10px 10px 0;
  }

  .inner-ctn {
    display: flex;
    align-items: center;
  }

  .popper {
    visibility: hidden;
  }
}
</style>
